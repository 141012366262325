import { isMatchWith, memoize } from 'lodash'

const timers = {} as any

const matchValue = (objValue: string, srcValue: string | string[]) => {
  if (Array.isArray(srcValue)) {
    return srcValue.includes(objValue)
  }
  return srcValue === objValue
}

export const isThisMatch = (object: object, source: object) => {
  return isMatchWith(object, source, matchValue)
}
export const debounce = (
  callback: Function,
  timeout: number = 300,
  key: string = 'main',
) => {
  clearTimeout(timers[key])
  timers[key] = setTimeout(callback, timeout)
}

export const tryCatchThrow = async (
  func: () => PromiseLike<unknown> | void,
  message?: string,
) => {
  try {
    return await func()
  } catch (error) {
    throw new Error(message || '')
  }
}

export const nextName = (name: string, arr: { title: string }[]) => {
  const reg = new RegExp(`${name}\\s*\\d*`)
  const matchCount = arr.reduce((max, { title }) => {
    if (reg.test(title)) {
      const num = Number(title?.match(/\d+$/))
      return num > max ? num : max
    }
    return max
  }, -1)
  return `${name} ${matchCount !== -1 ? matchCount + 1 : ''}`
}

export const debouncePromise = (
  callback: Function,
  timeout: number,
  key = 'default',
) => {
  return new Promise((resolve, reject) => {
    clearTimeout(timers[key])
    timers[key] = window.setTimeout(async () => {
      try {
        const result = await callback()
        resolve(result)
      } catch (error) {
        reject(error)
      }
    }, timeout)
  })
}

export const debounceFuncArr = (callback: Function, timeout: number) => {
  let timer = 0 as unknown as NodeJS.Timeout
  let savedArr = [] as string[]
  return (arr: string[]) => {
    return new Promise((resolve) => {
      clearTimeout(timer)
      savedArr = Array.from(new Set([...savedArr, ...arr]))
      timer = setTimeout(async () => {
        const result = await callback(savedArr)
        savedArr = []
        resolve(result)
      }, timeout)
    })
  }
}

export const insensitify = (str: string) =>
  str
    .split('')
    .map((char) => `[${char.toLowerCase()}${char.toUpperCase()}]`)
    .join('')

export const capitalize = memoize((str: string) => {
  return typeof str === 'string' && str !== ''
    ? str.replace(/^\w/, (c) => c.toLocaleUpperCase())
    : ''
})

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const calcCentroid = (pts: IPoint[]): { x: number; y: number } => {
  const nPts = pts.length
  if (nPts === 2) {
    return {
      x: (pts[1][0] - pts[0][0]) / 2 + pts[0][0],
      y: (pts[1][1] - pts[0][1]) / 2 + pts[0][1],
    }
  }
  const off = pts[0]
  let twiceArea = 0
  let x = 0
  let y = 0
  let p1: IPoint, p2: IPoint
  let f
  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i]
    p2 = pts[j]
    f =
      (p1[0] - off[0]) * (p2[1] - off[1]) - (p2[0] - off[0]) * (p1[1] - off[1])
    twiceArea += f
    x += (p1[0] + p2[0] - 2 * off[0]) * f
    y += (p1[1] + p2[1] - 2 * off[1]) * f
  }
  f = twiceArea * 3
  return {
    x: x / f + off[0],
    y: y / f + off[1],
  }
}

export const middlePoints = (pts: IPoint[]): IPoint[] => {
  const middlePoints = [] as IPoint[]
  for (let index = 1; index < pts.length; index++) {
    const element = pts[index - 1]
    const prevElement = pts[index]
    middlePoints.push([
      (element[0] - prevElement[0]) / 2 + prevElement[0],
      (element[1] - prevElement[1]) / 2 + prevElement[1],
    ])
  }
  const element = pts[pts.length - 1]
  const prevElement = pts[0]
  middlePoints.push([
    (element[0] - prevElement[0]) / 2 + prevElement[0],
    (element[1] - prevElement[1]) / 2 + prevElement[1],
  ])
  return middlePoints
}

export const vecToAngle = memoize((x, y) => {
  return Math.floor((Math.atan2(x, -y) * 180) / Math.PI)
})

export const angleToVec = memoize((angle) => {
  return [Math.sin(angle * 0.017453), -Math.cos(angle * 0.017453)]
})

interface NodeProps {
  treeId: string
  nodeId?: string
  body?: Record<string, any>
}

interface RelationsProps {
  treeId: string
  relationName: string
  relationId: string
  nodeId?: string
  body?: Record<string, any>
}

class COTApi {
  baseUrl: string = ''
  basePath: string = '/gateway/cameras/v1/complexObjectTree'

  builderUrl: Record<string, (args: any) => string> = {
    getNode: ({ treeId, nodeId }: Pick<NodeProps, 'nodeId' | 'treeId'>) =>
      `${this.baseUrl}/${treeId}/${nodeId}`,
    getRelation: ({
      treeId,
      relationName,
    }: Pick<RelationsProps, 'treeId' | 'relationName'>) =>
      `${this.baseUrl}/${treeId}/relations/${relationName}`,
    addChildToTree: ({ treeId, nodeId }: NodeProps) =>
      `${this.baseUrl}/${treeId}/childs${!nodeId ? '' : `/${nodeId}`}`,
    deleteChildFromTree: ({ treeId, nodeId }: NodeProps) =>
      `${this.baseUrl}/${treeId}/childs/${nodeId}`,
    addRelationToTree: ({
      relationName,
      treeId,
      nodeId,
    }: Omit<RelationsProps, 'relationId'>) =>
      `${this.baseUrl}/${treeId}${
        !nodeId ? '' : `/${nodeId}`
      }/relations/${relationName}`,
    deleteRelationFromTree: ({
      relationName,
      treeId,
      relationId,
      nodeId,
    }: RelationsProps) => {
      return `${this.baseUrl}/${treeId}${
        !nodeId ? '' : `/${nodeId}`
      }/relations/${relationName}/${relationId}`
    },
    patchRelationInTree: ({
      relationName,
      treeId,
      relationId,
      nodeId,
    }: RelationsProps) =>
      `${this.baseUrl}/${treeId}${
        !nodeId ? '' : `/${nodeId}`
      }/relations/${relationName}/${relationId}`,
  }

  constructor(url: string) {
    this.baseUrl = url + this.basePath
  }

  async request(url: string, options: Record<string, any>) {
    return await fetch(url, {
      ...options,
      headers: {
        'content-type': 'application/json',
        ...options?.headers,
      },
    })
  }

  async getRelationByTreeId({
    treeId,
    relationName,
  }: Pick<RelationsProps, 'treeId' | 'relationName'>) {
    const response = await this.request(
      this.builderUrl.getRelation({ treeId, relationName }),
      {
        method: 'GET',
      },
    )
    return await response.json()
  }

  async getNode({ treeId, nodeId }: Pick<NodeProps, 'nodeId' | 'treeId'>) {
    const response = await this.request(
      this.builderUrl.getNode({ treeId, nodeId }),
      {
        method: 'GET',
      },
    )
    return await response.json()
  }

  async addRelationToTree({
    body,
    relationName,
    treeId,
    nodeId,
  }: Omit<RelationsProps, 'relationId'>) {
    return await this.request(
      this.builderUrl.addRelationToTree({ treeId, relationName, nodeId }),
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
    )
  }

  async addChildToTree({ body, treeId, nodeId }: NodeProps) {
    return await this.request(
      this.builderUrl.addChildToTree({ treeId, nodeId }),
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
    )
  }

  async deleteChildFromTree(options: Omit<NodeProps, 'body'>) {
    return await this.request(this.builderUrl.deleteChildFromTree(options), {
      method: 'DELETE',
      headers: {
        'content-type': '',
      },
    })
  }

  async deleteRelationFromTree(options: Omit<RelationsProps, 'body'>) {
    return await this.request(this.builderUrl.deleteRelationFromTree(options), {
      method: 'DELETE',
      headers: {
        'content-type': '',
      },
    })
  }

  async updateRelationInTree(options: RelationsProps) {
    return await this.request(this.builderUrl.patchRelationInTree(options), {
      method: 'PATCH',
      body: JSON.stringify(options.body),
    })
  }
}

const api = new COTApi('')
export { api as COTApi }

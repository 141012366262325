var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-d-flex",staticStyle:{"width":"100vw","height":"100vh","overflow":"hidden","position":"absolute"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[(_vm.editorMode === 'archive')?_c('ArchiveEditor'):_vm._e(),(_vm.editorMode === 'areas')?_c('AreasEditor'):_vm._e(),(_vm.editorMode === 'calibration')?_c('CalibrationEditor'):_vm._e(),(_vm.editorMode === 'presets')?_c('PresetEditor'):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editorMode === 'areas'),expression:"editorMode === 'areas'"}],staticClass:"fields-bar"},[_c('AssignmentsBlock'),(_vm.assignments.length > 0 && !_vm.networkError)?_c('CommonPropertiesBlock',{attrs:{"assignmentGroup":_vm.assignmentGroup}}):_vm._e(),_vm._l((_vm.areasUiSchemas),function([assignment, areasProps]){return _c('div',{key:`areas_${assignment.id}`},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(assignment === _vm.currentAssignment && !_vm.networkError),expression:"assignment === currentAssignment && !networkError"}]},_vm._l((areasProps),function(uiSchema){return _c('AreasBlock',{key:`${assignment}${uiSchema.model}`,attrs:{"uiSchema":uiSchema,"assignment":assignment}})}),1)])}),(!_vm.networkError)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.assignments.length > 0 && _vm.currentAssignment === null && !_vm.networkError
      ),expression:"\n        assignments.length > 0 && currentAssignment === null && !networkError\n      "}]},[_c('AreasBlock',{attrs:{"uiSchema":{
          fieldOptions: {
            props: { options: { label: 'area', type: 'common' } },
          },
        }}})],1):_vm._e(),(!_vm.networkError)?_c('div',_vm._l((_vm.assignments),function(assignment){return _c('PropertiesBlock',{key:assignment.id,style:({
          display:
            assignment !== _vm.currentAssignment ? 'none !important' : 'flex',
        }),attrs:{"assignment":assignment}})}),1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }

import Dropdown from 'primevue/dropdown'
import Te from '../common/Te'

const commit = new CustomEvent('commit', {
  bubbles: true,
})

type Options = {
  label: string
  type: string
  default: string
  options: { title: string; value: string }[]
} & UISchema['fieldOptions']['props']['options']

export default Te.extend({
  name: 'EnumField',
  components: {
    Dropdown,
  },
  props: {
    value: { type: String, default: undefined },
    options: {
      type: Object as () => Options,
      default: (): Partial<Options> => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    localValue: {
      set(val: string) {
        this.$emit('commit', val)
        this.$el.dispatchEvent(commit)
      },
      get(): string {
        return this.value
      },
    },
  },
  mounted() {
    // set default or first if it single
    if (this.value === undefined) {
      if (this.options.default) {
        setTimeout(() => {
          this.options.default && (this.localValue = this.options.default)
        })
      } else if (this.options.options && this.options.options.length === 1) {
        setTimeout(() => {
          this.localValue = this.options.options[0].value
        })
      }
    }
  },
  methods: {
    getLocale(val: any): string {
      const option = this.options.options
        ? this.options.options.find(({ value }: any) => value === val)
        : { title: '', label: '' }
      return option
        ? String(
            this.te(
              `assignments.${this.options.type}.enumOptions.${option.title}`,
              option.title,
            ),
          )
        : ''
    },
  },
})

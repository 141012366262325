export const RUSSIAN_TRANSLATIONS = {
  assignmentStatuses: {
    Rejected: 'Отказано',
    Stopped: 'Остановлена',
    NotStarted: 'Не запущена',
    Starting: 'Запускается',
    Stopping: 'Останавливается',
    Started: 'Запущена',
  },
  defaultPresetName: 'Начальный кадр',
  areas: 'Зоны',
  allAreas: 'Все зоны',
  emptyAreas: 'Добавьте зоны',
  button: {
    ok: 'Ок',
    save: 'Сохранить',
    cancel: 'Отмена',
    yes: 'Да',
    no: 'Нет',
    line: 'Линия',
    zone: 'Зона',
    nodirection: 'Без направления',
    direction: 'В одну сторону',
    bidirectional: 'В обе стороны',
    delete: 'Удалить',
    do: 'Назад',
    undo: 'Отменить',
    preset: 'Редактировать',
    calibration: 'Калибровка',
    back: 'Назад',
    addZone: 'Добавить зону',
    show: 'Показать',
    showAll: 'Показать все',
    hide: 'Скрыть',
    hideAll: 'Скрыть все',
    addAssignment: 'Добавить аналитику',
    rename: 'Переименовать',
    startAssignmentGroup: 'Запустить все',
    pauseAssignmentGroup: 'Остановить все',
    unableToStartAssignmentGroup: 'Невозможно запустить группу с невалидными аналитиками',
    startAssignment: 'Запустить',
    stopAssignment: 'Остановить',
    restartAssignment: 'Перезапустить',
    deleteArea: 'Удалить зону',
    clear: 'Очистить',
    updatePresetImage: 'Обновить скриншот',
  },
  assignments: {
    common: {
      area: 'Зоны',
    },
  },
  search: 'Поиск',
  error: 'Ошибка',
  loading: 'Загрузка',
  nothingFound: 'Ничего не найдено',
  notDefined: 'Не определен',
  emptyAssignments: 'Добавьте аналитику',
  updatePresetPreview: 'Обновите скриншот позиции',
  calibration: {
    showGrid: 'Показать сетку',
    Width: 'Ширина',
    Height: 'Длина',
    rectangleHeight: 'Длина, м',
    rectangleWidth: 'Ширина, м',
    calibrationRequired: 'Калибровка обязательна',
  },
  blocksHeaders: {
    assignments: 'Аналитики',
    commonProps: 'Общие параметры',
    areas: 'Зоны наблюдений',
    enitityProps: 'Параметры аналитики',
    groupEnitityProps: 'Общие параметры',
  },
  message: {
    deleteRelations: 'Отвязать зону?',
    editBlocked:
      'Изменения данной зоны затронут другие аналитики, все равно изменить зону?',
    editTitle: 'Изменить',
    areaBlocked: 'Нельзя удалить выбранную зону',
    deleteTitle: 'Удалить',
    deleteAssignment: 'Удалить аналитику',
    deleteArea: 'Удалить зону?',
    drawStartHint:
      'Для начала рисования кликните на любом месте изображения с камеры',
    drawEndLineHint:
      'Для завершения линии кликните на любом месте изображение с камеры',
    drawMiddlePoligonPoints: 'Добавьте еще одну точку полигона',
    drawEndPolygonHint:
      'Для завершения полигона кликните на первую точку полигона',
  },
  form: {
    choose: 'Выбрать',
    selected: 'выбрано',
  },
  areaType: {
    Line: 'Граница',
    Polygon: 'Зона',
  },
  required: 'Требуется',
  directionType: {
    None: 'без направления',
    Directed: 'направленная',
    Bidirectional: 'двунаправленная',
  },
  attrs: {
    preset: 'Позиции камеры',
    threshold: 'Чувствительность',
    previewGenerationDelay: 'Задержка создания превью',
    syncMode: 'Режим синхронизации',
    priority: 'Приоритет',
    typesForControl: 'Объекты контроля',
    someBool: 'Пример булева поля',
  },
  enumOptions: {
    Metadata: 'Метаданные',
    all: 'Все',
  },
  assignmentRejectReason: {
    Unknown: 'Неизвестная причина',
    ByTimeout: 'Таймаут ответа от аналитики',
    ByError: 'Аналитики прервали обработку видеопотока',
    IsNotConfirmed:
      'Аналитики не подтвердили запуск из-за невалидных данных параметров',
    external_ByError: 'Внешние аналитики не подтвердили запуск видеопотока',
    external_ByTimeout: 'Таймаут ответа от внешней аналитики',
    external_CameraPositionInvalidated: 'Произошла смена положения PTZ камеры',
    external_NoTypeField: 'Ошибка интеграции. Обратитесь в тех.поддержку',
    external_UnknownMessageType:
      'Ошибка интеграции. Обратитесь в тех.поддержку',
    external_RejectedBySchema: 'Ошибка интеграции. Обратитесь в тех.поддержку',
    external_InternalError: 'Ошибка интеграции. Обратитесь в тех.поддержку',
    external_StreamDoesntExist: 'Ошибка интеграции. Обратитесь в тех.поддержку',
    external_PrepareStreamExpectedFirst:
      'Ошибка интеграции. Обратитесь в тех.поддержку',
    external_StreamNeitherRunningNorPreparing: 'Видеопоток не запущен',
    external_StreamIsRunningAlready: 'Видеопоток уже запущен',
  },
  errorMessages: {
    assignmentGroupCreatingError: 'Невозможно сохранить группу',
    assignmentGroupUpdatingError: 'Невозможно обновить группу',
    assignmentGroupDeletingError: 'Невозможно удалить группу',
    assignmentGroupStartingError: 'Невозможно запустить группу',
    assignmentGroupStopError: 'Невозможно остановить группу',
    assignmentCreatingError: 'Невозможно создать аналитику',
    assignmentUpdatingError: 'Невозможно обновить аналитику',
    assignmentDeletingError: 'Невозможно удалить аналитику',
    assignmentStartingError: 'Невозможно запустить аналитику',
    assignmentStopError: 'Невозможно остановить аналитику',
    areaCreatingError: 'Невозможно создать зону',
    areasSavingError: 'Невозможно сохранить зону',
    fetchAraesError: 'Невозможно получить зоны',
    zoneDeletingError: 'Выбранная зона не может быть удалена',
    renamingError: 'Переименование невозможно',
    calibrationError: 'Ошибка калибровки',
    featuresLimitExceeded: 'Превышен лимит количества опций',
    networkError:
      'Ошибка сети или данных. Проверьте подключение или обратитеть в техническую поддержку.',
  },
}


import FieldsBlock from '../common/FieldsBlock.vue'
import YesNoDialog from '../common/YesNoDialog.vue'
import { mapActionsTyped, mapGettersTyped, mapStateTyped } from '@/store'
import Checkbox from 'primevue/checkbox'
import RadioButton from 'primevue/radiobutton'
import BatchQueue from '../common/BatchQueue'
import RelationBinder from '../common/RelationBinder.vue'
import { COTApi } from '@/COT'
import { isArray } from 'lodash'
import { debounce, isThisMatch } from '@/utils'

type AreaUISchema = {
  component: 'area'
  fieldOptions: {
    props: {
      options: {
        matchTo?: {
          areaType: IPresetArea['areaType']
          directionType:
            | IPresetArea['directionType']
            | IPresetArea['directionType'][]
        }
        isArray?: boolean
      }
    }
  }
} & UISchema

const AREA_TYPE_BUTTON = {
  Line: 'mdi-vector-line',
  Polygon: 'mdi-shape-polygon-plus',
}

type AreasEntitieMatchMap = Record<
  IPresetArea['id'],
  Partial<IEntity> | undefined
>

export default BatchQueue.extend({
  name: 'AreaBlock',
  components: {
    FieldsBlock,
    YesNoDialog,
    RelationBinder,
  },
  props: {
    assignment: Object as () => IAssignment | undefined,
    uiSchema: Object as () => AreaUISchema,
  },
  data() {
    return {
      Checkbox,
      RadioButton,
      showContent: true,
      displayDeleteDialog: false,
      lastAreasCount: 0,
      currentRenaming: null as IPresetArea | null,
      currentRenamingString: '',
      areaForDelete: null as IPresetArea | null,
      aggregator: {} as IAssignmentGroup['parameters'],
    }
  },
  computed: {
    ...mapStateTyped([
      'complexObjectTree',
      'assignmentTypes',
      'areas',
      'hiddenAreas',
      'editingArea',
      'hoveredArea',
      'assignmentGroup',
      'matchedZones',
      'allSelectedAreas',
      'zonesRequirements',
      'errorsState',
      'widgetProps',
      'areasLoadStatus',
    ]),
    ...mapGettersTyped(['blockedAreas', 'presetAreaRelationsMap']),
    selectedAreas: {
      set(val: string | string[] | undefined) {
        if (!this.assignment) return

        this.setAllSelectedArea(val)
        this.tryCatch(() =>
          this.updateAssignment({
            ...this.assignment,
            parameters: {
              ...this.assignment?.parameters,
              [this.uiSchema.model]: val,
            },
          } as IAssignment),
        )
      },
      get(): string | string[] | undefined {
        return this.assignment
          ? (this.assignment.parameters[this.uiSchema.model] as
              | string
              | string[]) || undefined
          : []
      },
    },
    myHiddenAreas(): IPresetArea[] {
      return this.myAreas.filter((area) => this.hiddenAreas.includes(area.id))
    },
    isError(): boolean {
      return (
        this.errorsState?.[this.assignment?.id || '']?.state?.[
          this.uiSchema.model
          // @ts-ignore
        ]?.vfjsFieldErrors?.length > 0
      )
    },
    isEmpty(): boolean {
      if (!this.assignment) return false
      const { parameters } = this.assignment
      if (!parameters) return true
      return Array.isArray(parameters[this.uiSchema.model])
        ? (parameters[this.uiSchema.model] as string[]).length === 0
        : !this.assignment.parameters[this.uiSchema.model]
    },
    isArrayArea(): boolean {
      return !!this.uiSchema.fieldOptions.props.options.isArray
    },
    addIcon(): string {
      const { matchTo } = this.uiSchema.fieldOptions.props.options
      return matchTo?.areaType && matchTo.areaType in AREA_TYPE_BUTTON
        ? AREA_TYPE_BUTTON[matchTo.areaType]
        : 'mdi-plus'
    },
    matchedEntitiesMap(): AreasEntitieMatchMap {
      return this.myAreas.reduce((acc, area) => {
        acc[area.id] = this.matchEntity(area)
        return acc
      }, {} as AreasEntitieMatchMap)
    },
    myAreas(): IPresetArea[] {
      if (!this.assignment) return this.areas
      const { matchTo } = this.uiSchema.fieldOptions.props.options
      if (matchTo) {
        const matched = this.areas.filter((area) => isThisMatch(area, matchTo))
        /* eslint-disable vue/no-async-in-computed-properties */
        setTimeout(() => {
          if (!this.assignment) return
          this.$store.commit('setValue', [
            'matchedZones',
            {
              ...this.matchedZones,
              [this.assignment.id]: {
                ...this.matchedZones[this.assignment.id],
                [this.uiSchema.model]: matched,
              },
            },
          ])
        })
        setTimeout(() => {
          if (!this.assignment) return
          this.$store.commit('setValue', [
            'zonesRequirements',
            {
              ...this.zonesRequirements,
              [this.assignment.id]: {
                ...this.zonesRequirements[this.assignment.id],
                [this.uiSchema.model]: this.isEmpty
                  ? `${this.$t('required')} ${`${this.te(
                      `areaType.${matchTo.areaType}`,
                      matchTo.areaType,
                    )} ${
                      isArray(matchTo.directionType)
                        ? matchTo.directionType
                            .map((type) => this.$t(`directionType.${type}`))
                            .join(', ')
                        : this.$t(`directionType.${matchTo.directionType}`)
                    }`.toLowerCase()}`
                  : null,
              },
            },
          ])
        })
        return matched
      }
      return this.areas
    },
  },
  watch: {
    myAreas(val: IPresetArea[]) {
      const matchedAreasIds = val.map(({ id }) => id)
      if (Array.isArray(this.selectedAreas)) {
        const selectedMatchedAreasIds = this.selectedAreas.filter((areaId) =>
          matchedAreasIds.includes(areaId),
        )

        if (
          ['complete', 'empty'].includes(this.areasLoadStatus) &&
          this.areasLoadStatus &&
          selectedMatchedAreasIds.length !== this.selectedAreas.length
        ) {
          this.selectedAreas = selectedMatchedAreasIds
        }
      } else {
        this.selectedAreas &&
          !matchedAreasIds.includes(this.selectedAreas) &&
          ['complete', 'empty'].includes(this.areasLoadStatus) &&
          (this.selectedAreas = undefined)
      }
      this.lastAreasCount !== val.length &&
        (this.showContent = true) &&
        (this.lastAreasCount = val.length)
    },
  },
  mounted() {
    this.setAllSelectedArea(this.selectedAreas)
    this.$eventBus.$on('updatePresetId', this.dropSelectedAreas)
  },
  methods: {
    ...mapActionsTyped([
      'updateEntity',
      'updateAssignment',
      'deleteArea',
      'deleteRelation',
    ]),
    hideArea(id: string) {
      this.$store.commit('setValue', ['hiddenAreas', [...this.hiddenAreas, id]])
    },
    dropSelectedAreas() {
      this.selectedAreas = this.isArrayArea ? [] : undefined
    },
    matchEntity(area: IPresetArea): Partial<IEntity> | undefined {
      const { areasMatching } = this.widgetProps
      if (!areasMatching) return
      const entitityMatch = areasMatching.find(([areaMatch]) =>
        isThisMatch(area, areaMatch)
      )
      return entitityMatch ? entitityMatch[1] : undefined
    },
    showAllAreas() {
      this.$store.commit('setValue', ['hiddenAreas', []])
    },
    hideAllAreas() {
      this.$store.commit('setValue', [
        'hiddenAreas',
        this.myAreas.map(({ id }) => id),
      ])
    },
    startDrawArea() {
      const { matchTo } = this.uiSchema.fieldOptions.props.options
      this.$store.commit('setValue', [
        'currentDrawTool',
        matchTo?.areaType || 'Polygon',
      ])
      this.$store.commit('setValue', [
        'currentDrawDirection',
        isArray(matchTo?.directionType)
          ? matchTo?.directionType[0]
          : matchTo?.directionType || 'None',
      ])
    },
    showArea(id: string) {
      this.$store.commit('setValue', [
        'hiddenAreas',
        this.hiddenAreas.filter((areaId) => id !== areaId),
      ])
    },
    setRenaming(area: IPresetArea) {
      this.currentRenamingString = area.title
      this.currentRenaming = area
      let input = this.$refs[area.id] as HTMLInputElement
      input = Array.isArray(input) ? input[0] : input
      input && setTimeout(() => input.focus(), 100)
    },
    setAllSelectedArea(val: string | string[] | undefined) {
      setTimeout(() => {
        if (!this.assignment) return
        this.$store.commit('setValue', [
          'allSelectedAreas',
          {
            ...this.allSelectedAreas,
            [this.assignment.id]: {
              ...this.allSelectedAreas[this.assignment.id],
              [this.uiSchema.model]: Array.isArray(val) ? val : [val],
            },
          },
        ])
      })
    },
    rename() {
      if (
        this.currentRenaming &&
        this.currentRenamingString !== this.currentRenaming.title
      ) {
        const presetArea = { ...this.currentRenaming } as IPresetArea
        this.currentRenaming.title = this.currentRenamingString
        debounce(
          async () =>
            this.tryCatch(
              () =>
                this.updateEntity([
                  presetArea,
                  {
                    title: this.currentRenamingString,
                  },
                ]),
              `${this.$t('errorMessages.renamingError')}`,
            ),
          300,
          `area${presetArea}`,
        )
      }
      this.currentRenaming = null
    },
    setCurrentAssignment(
      assignment: IAssignment | Partial<IAssignment> | null,
    ) {
      this.$store.commit('setValue', ['currentAssignment', assignment])
    },
    setHovered(area: IPresetArea) {
      this.$store.commit('setValue', ['hoveredArea', area])
    },
    clearHovered(area: IPresetArea) {
      if (this.hoveredArea === area) {
        this.$store.commit('setValue', ['hoveredArea', null])
      }
    },
    addRelation(
      area: IPresetArea,
      {
        value: { title, value, type },
      }: {
        value: { title: string; value: IEntity['id']; type: IEntity['type'] }
      },
    ) {
      this.updateEntity([
        area,
        {
          title,
          hasRelation: true,
        },
      ])
      COTApi.addRelationToTree({
        body: {
          target: { id: area.id, type: 'PresetArea' },
          source: { id: value, type },
        },
        relationName: 'presetArea',
        treeId: this.complexObjectTree.id,
      })
    },
    async deleteCurrentArea() {
      if (this.areaForDelete) {
        const result = await this.deleteArea(this.areaForDelete)
        !result &&
          this.errorToast({
            message: this.$t('errorMessages.zoneDeletingError'),
          })
      }
      this.displayDeleteDialog = false
    },
  },
  destroyed() {
    this.$eventBus.$off('updatePresetId', this.dropSelectedAreas)
  },
})

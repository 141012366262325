
import Vue from 'vue'
import YesNoDialog from '../common/YesNoDialog.vue'
import { mapStateTyped, mapGettersTyped } from '@/store'
import { isThisMatch } from '@/utils'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'RelationBinder',
  components: {
    YesNoDialog,
  },
  props: {
    presetArea: Object as () => IPresetArea,
    matchedEntity: Object as () => Partial<IEntity>,
  },
  data() {
    return {
      displayDeleteDialog: false,
    }
  },
  computed: {
    ...mapGettersTyped(['presetAreaRelationsMap']),
    ...mapStateTyped(['treeChildren']),
    bindedComlexObjectIds(): string[] {
      return Object.values(this.presetAreaRelationsMap).map(
        ({ source }) => source,
      )
    },
    dropdownOptions():
      | { title: string; value: string; type: string }[]
      | { title: TranslateResult }[] {
      const options = this.treeChildren.reduce<
        Array<{ title: string; value: string; type: string }>
      >((acc, next) => {
        if (
          isThisMatch(next, this.matchedEntity) &&
          !this.bindedComlexObjectIds.includes(next.id)
        ) {
          acc.push({ title: next.title, value: next.id, type: next.type })
        }
        return acc
      }, [])

      if (options?.length) {
        return options
      } else {
        return [{ title: this.$i18n.t('nothingFound') }]
      }
    },
    isBinded(): boolean {
      return !!this.presetAreaRelationsMap[this.presetArea.id]
    },
  },
  methods: {
    checkAndPassEvent({
      value,
    }: {
      value: { title: string; value?: string; type?: string }
    }) {
      if (value.value && value.type) {
        this.$emit('select', { value })
      }
    },
  },
})


import Vue from 'vue'

export default Vue.extend({
  name: 'Movable',
  data() {
    return {
      clientX: 0,
      clientY: 0,
    }
  },
  methods: {
    onDragStart(e: any) {
      ;[this.clientX, this.clientY] = [e.clientX, e.clientY]
    },
    onDragEnd(e: any) {
      const clientX = this.clientX - e.clientX
      const clientY = this.clientY - e.clientY
      e.srcElement.style.top = e.srcElement.offsetTop - clientY + 'px'
      e.srcElement.style.left = e.srcElement.offsetLeft - clientX + 'px'
    },
  },
})


import Vue from 'vue'
import { dateTimeFormats } from '@/translations/dateTimeLocale'
import Calendar from 'primevue/calendar'

const commit = new CustomEvent('commit', {
  bubbles: true,
})

const isValidDate = (d: unknown) => {
  // @ts-ignore
  return d instanceof Date && !isNaN(d)
}

export default Vue.extend({
  name: 'DateField',
  components: { Calendar },
  props: {
    value: { type: [Number, String, Date], default: undefined },
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return { dateLocale: dateTimeFormats[this.$i18n.locale] }
  },
  computed: {
    localValue: {
      set(val: Date) {
        this.$emit('commit', val)
        this.$el.dispatchEvent(commit)
      },
      get(): Date | undefined {
        const date = new Date(this.value)
        return (isValidDate(new Date(date)) && date) || undefined
      },
    },
  },
  mounted() {
    this.value === undefined &&
      typeof this.options.default === 'string' &&
      setTimeout(() => (this.localValue = this.options.default))
  },
  methods: {
    clearDate() {
      this.localValue = undefined
    },
  },
})

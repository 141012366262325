export const localize = (errors: any) => {
  if (!(errors && errors.length)) return
  for (const e of errors) {
    let out
    switch (e.keyword) {
      case 'additionalItems':
      case 'items':
        out = ''
        out += 'должно иметь не более, чем ' + e.params.limit + ' элемент'
        if (e.params.limit >= 2 && e.params.limit <= 4) {
          out += 'а'
        } else if (e.params.limit !== 1) {
          out += 'ов'
        }
        break
      case 'additionalProperties':
        out = 'не должно иметь дополнительных полей'
        break
      case 'anyOf':
        out = 'должно соответствовать одной их схем в "anyOf"'
        break
      case 'const':
        out = 'должно быть равно заданному значению'
        break
      case 'contains':
        out = 'должно содержать значение соответствующее схеме'
        break
      case 'dependencies':
      case 'dependentRequired':
        out = ''
        out += 'должно иметь пол'
        if (e.params.depsCount === 1) {
          out += 'е'
        } else {
          out += 'я'
        }
        out +=
          ' ' + e.params.deps + ', когда присутствует поле ' + e.params.property
        break
      case 'discriminator':
        switch (e.params.error) {
          case 'tag':
            out = 'поле "' + e.params.tag + '" должно быть строкой'
            break
          case 'mapping':
            out =
              'значение поля "' +
              e.params.tag +
              '" должно быть в одной из oneOf схем '
            break
          default:
            out = 'должно соответствовать правилу "' + e.keyword + '"'
        }
        break
      case 'enum':
        out = 'должно быть равно одному из разрешенных значений'
        break
      case 'false schema':
        out = 'схема равна false'
        break
      case 'format':
        out = 'должно соответствовать формату "' + e.params.format + '"'
        break
      case 'formatMaximum':
      case 'formatExclusiveMaximum':
        out = ''
        out += 'должно быть ' + e.params.comparison + ' ' + e.params.limit
        break
      case 'formatMinimum':
      case 'formatExclusiveMinimum':
        out = ''
        out += 'должно быть ' + e.params.comparison + ' ' + e.params.limit
        break
      case 'if':
        out = 'должно соответствовать схемe "' + e.params.failingKeyword + '"'
        break
      case 'maximum':
      case 'exclusiveMaximum':
        out = ''
        out += 'должно быть ' + e.params.comparison + ' ' + e.params.limit
        break
      case 'maxItems':
        out = ''
        out += 'должно иметь не более, чем ' + e.params.limit + ' элемент'
        if (e.params.limit >= 2 && e.params.limit <= 4) {
          out += 'а'
        } else if (e.params.limit !== 1) {
          out += 'ов'
        }
        break
      case 'maxLength':
        out = ''
        out += 'должно быть не длиннее, чем ' + e.params.limit + ' символ'
        if (e.params.limit >= 2 && e.params.limit <= 4) {
          out += 'а'
        } else if (e.params.limit !== 1) {
          out += 'ов'
        }
        break
      case 'maxProperties':
        out = ''
        out += 'должно иметь не более, чем ' + e.params.limit + ' пол'
        if (e.params.limit === 1) {
          out += 'е'
        } else if (e.params.limit >= 2 && e.params.limit <= 4) {
          out += 'я'
        } else {
          out += 'ей'
        }
        break
      case 'minimum':
      case 'exclusiveMinimum':
        out = ''
        out += 'должно быть ' + e.params.comparison + ' ' + e.params.limit
        break
      case 'minItems':
        out = e.dataPath
        out += ' должно иметь не менее, чем ' + e.params.limit + ' элемент'
        if (e.params.limit >= 2 && e.params.limit <= 4) {
          out += 'а'
        } else if (e.params.limit !== 1) {
          out += 'ов'
        }
        break
      case 'minLength':
        out = ''
        out += 'должно быть не короче, чем ' + e.params.limit + ' символ'
        if (e.params.limit >= 2 && e.params.limit <= 4) {
          out += 'а'
        } else if (e.params.limit !== 1) {
          out += 'ов'
        }
        break
      case 'minProperties':
        out = ''
        out += 'должно иметь не менее, чем ' + e.params.limit + ' пол'
        if (e.params.limit === 1) {
          out += 'е'
        } else if (e.params.limit >= 2 && e.params.limit <= 4) {
          out += 'я'
        } else {
          out += 'ей'
        }
        break
      case 'multipleOf':
        out = 'должно быть кратным ' + e.params.multipleOf
        break
      case 'not':
        out = 'должно не соответствовать схеме в "not"'
        break
      case 'oneOf':
        out = 'должно соответствовать в точности одной схемe в "oneOf"'
        break
      case 'pattern':
        out = 'должно соответствовать образцу "' + e.params.pattern + '"'
        break
      case 'patternRequired':
        out =
          'должно иметь поле, соответствующее образцу "' +
          e.params.missingPattern +
          '"'
        break
      case 'propertyNames':
        out = 'имя поля не соответствует схеме'
        break
      case 'required':
        out = `Поле ${e.params.missingProperty} обязательно`
        break
      case 'type':
        out = 'должно быть ' + e.params.type
        break
      case 'unevaluatedItems':
        out = ''
        out += 'должно иметь не более, чем ' + e.params.len + ' элемент'
        if (e.params.len >= 2 && e.params.len <= 4) {
          out += 'а'
        } else if (e.params.len !== 1) {
          out += 'ов'
        }
        break
      case 'unevaluatedProperties':
        out = 'не должно иметь непроверенных полей'
        break
      case 'uniqueItems':
        out =
          'не должно иметь повторяющихся элементов (элементы ' +
          e.params.j +
          ' и ' +
          e.params.i +
          ' идентичны)'
        break
      default:
        out = 'должно соответствовать правилу "' + e.keyword + '"'
    }
    e.message = out
  }
}

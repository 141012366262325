
import { mapStateTyped, mapGettersTyped, mapActionsTyped } from '@/store'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import { uuid } from '@/utils'
import BatchQueue from '../common/BatchQueue'

const commit = new CustomEvent('commit', {
  bubbles: true,
})

const DEFAULT_CAMERA_THRESHOLD = {
  x: 0.001,
  y: 0.001,
  zoom: 0.003,
}

interface Eq<A> {
  readonly equals: (a: A, b: A) => boolean
}

const getEqNumber = (threshold: number): Eq<number> => {
  return {
    equals: (a, b) => Math.abs(a - b) < threshold,
  }
}

const getEqPosition = (threshold: IPtzValues): Eq<IPtzValues> => {
  const eqEntries = Object.entries(threshold).map(
    ([key, value]) =>
      [key, getEqNumber(value)] as [keyof IPtzValues, Eq<number>],
  )
  return {
    equals: (a, b) => {
      if (a === b) {
        return true
      }
      return eqEntries.every(([key, eq]) => eq.equals(a[key], b[key]))
    },
  }
}

type Options = {
  label: string
  editable: boolean
  entityType: string
  labelField: string
  entityField: string
  disabled: boolean
}

export default BatchQueue.extend({
  name: 'PresetField',
  props: {
    value: { type: String, default: '' },
    options: {
      type: Object as () => Options,
      default: (): Partial<Options> => ({}),
    },
  },
  data() {
    return {
      prevVal: '',
    }
  },
  computed: {
    ...mapStateTyped(['widgetProps', 'presets', 'editorMode', 'camera']),
    ...mapGettersTyped(['presetType']),
    localValue: {
      async set(val: string) {
        if (this.prevVal !== val) {
          this.$emit('commit', val)
          this.$eventBus.$emit('updatePresetId')
          this.fetchAreas(val)
          this.$el.dispatchEvent(commit)
        }
        this.prevVal = val
      },
      get(): string {
        return this.value
      },
    },
  },
  async mounted() {
    !this.presets && (await this.$store.dispatch('fetchPresets'))
    this.value &&
      this.tryCatch(
        () => this.fetchAreas(this.value),
        `${this.$t('errorMessages.fetchAraesError')}`,
      )
    this.presets?.length === 0 &&
      this.widgetProps.entityType !== 'Camera' &&
      (await this.createPreset()) &&
      (await this.$store.dispatch('fetchPresets'))

    if (this.value) {
      this.prevVal = this.value
      return
    }
    if (this.camera?.status?.position && this.camera.ptzSettings) {
      const { threshold } = this.camera.ptzSettings
      const eqPosition = getEqPosition(threshold ?? DEFAULT_CAMERA_THRESHOLD)
      const matchingPreset = this.presets?.find(
        (p) =>
          this.camera &&
          eqPosition.equals(this.camera.status.position, p.status.position),
      )
      matchingPreset && (this.localValue = matchingPreset.id)
    } else if (this.presets?.length === 1) {
      this.localValue = this.presets[0].id
    }
  },
  methods: {
    ...mapActionsTyped(['fetchAreas']),
    createPreset(): Promise<unknown> {
      const payload = {
        id: uuid(),
        type: 'Preset',
        entityId: this.widgetProps.entityId,
        entityType: this.widgetProps.entityType,
        groundCalibration: {},
        title: this.$t('defaultPresetName'),
      }
      return createCamerasConnection().v2.createEntity(payload, {
        keyValues: true,
      })
    },
  },
})

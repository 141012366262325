
import Te from '../common/Te'
import MultiSelect from 'primevue/multiselect'
type Options = {
  label: string
  default: string[]
  disabled: boolean
  options: { title: string; value: string; type: string }[]
  type: string
}
const commit = new CustomEvent('commit', {
  bubbles: true,
})

export default Te.extend({
  name: 'EnumField',
  components: {
    MultiSelect,
  },
  props: {
    value: { type: Array as () => string[], default: () => [] },
    options: {
      type: Object as () => Options,
      default: (): Partial<Options> => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    localValue: {
      set(val: string[]) {
        this.$emit('commit', val.length ? val : undefined)
        this.$el.dispatchEvent(commit)
      },
      get(): string[] {
        return this.value
      },
    },
  },
  mounted() {
    this.value?.length === 0 &&
      Array.isArray(this.options.default) &&
      setTimeout(
        () => this.options.default && (this.localValue = this.options.default),
      )
  },
  methods: {
    localize(terms: string[]) {
      return terms.map((val) => `${this.getLocale(val) || val}`).join(', ')
    },
    getLocale(val: string): string {
      const option = this.options.options
        ? this.options.options.find(({ value }) => value === val)
        : { title: '', label: '' }
      return option
        ? String(
            this.$t(
              `assignments.${this.options.type}.enumOptions.${option.title}`,
            ),
          )
        : ''
    },
  },
})

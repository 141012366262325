
import Vue from 'vue'
const commit = new CustomEvent('commit', {
  bubbles: true,
})

export default Vue.extend({
  name: 'URLField',
  props: {
    value: String,
    options: Object,
  },
  data() {
    return {}
  },
  computed: {
    localValue: {
      set(val: string) {
        this.$emit(
          'commit',
          encodeURIComponent(val).replace(/[!'()*]/g, (c) => {
            return '%' + c.charCodeAt(0).toString(16)
          }),
        )
        this.$el.dispatchEvent(commit)
      },
      get(): Object {
        return decodeURIComponent(this.value || '')
      },
    },
  },
  mounted() {
    this.value === undefined &&
      typeof this.options.default === 'string' &&
      setTimeout(() => (this.localValue = this.options.default))
  },
})

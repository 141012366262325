
import {
  calcCentroid,
  middlePoints,
  uuid,
  vecToAngle,
  debounce,
  angleToVec,
  nextName,
} from '@/utils'
import { createEntity, updateEntity } from '@/api'
import YesNoDialog from '../common/YesNoDialog.vue'
import Movable from '../common/Movable.vue'
import BatchQueue from '../common/BatchQueue'
import { memoize } from 'lodash'
import {
  mapStateTyped,
  mapGettersTyped,
  mapMutationsTyped,
  mapActionsTyped,
} from '@/store'
type AdditionalToolsNames =
  | 'do'
  | 'undo'
  | 'nodirection'
  | 'direction'
  | 'bidirectional'
  | 'delete'

type Tool<T> = {
  name: T
  icon: string
  label: string
  disabled: boolean
  action: (args: any) => void
}

const directionMap = {
  nodirection: 'None',
  direction: 'Directed',
  bidirectional: 'Bidirectional',
} as Record<AdditionalToolsNames, IPresetArea['directionType']>

export default BatchQueue.extend({
  name: 'Canvas',
  components: {
    YesNoDialog,
    Movable,
  },
  props: {
    width: { type: Number, default: 1024, required: true },
    height: { type: Number, default: 800, required: true },
    naturalHeight: { type: Number, default: 800, required: true },
    naturalWidth: { type: Number, default: 800, required: true },
  },
  data() {
    return {
      arrowLength: 150,
      start: { x: 0, y: 0 },
      centroidsMap: {} as Record<IPresetArea['id'], XYpoint>,
      middlePointsMap: {} as Record<IPresetArea['id'], IPoint[]>,
      lastPoint: [0, 0] as IPoint,
      isDragging: false,
      displayDeleteDialog: false,
      displayEditBlocked: false,
      ignoredBlocking: [] as IPresetArea['id'][],
      ignoringCandidate: '' as IPresetArea['id'],
      editCallback: () => {},
      areaForDelete: null as IPresetArea | null,
      drag: {
        x: 0,
        y: 0,
      },
      canvasOffsets: [0, 0, 0, 0] as [number, number, number, number],
    }
  },
  computed: {
    allAreas(): IPresetArea[] {
      return this.allRowAreas?.map((area) => {
        const isAbsolute = area.area.some(([x, y]) => x > 1 || y > 1)
        if (isAbsolute) {
          area.absoluteArea = area.area
        } else {
          area.absoluteArea = this.relativeToAbsoluteCoordinates(area.area)
        }

        return area
      })
    },
    allRowAreas: mapStateTyped(['areas']).areas,
    ...mapStateTyped([
      'hoveredArea',
      'hiddenAreas',
      'editingArea',
      'assignmentGroup',
      'currentDrawTool',
      'currentDrawDirection',
      'currentDrawingPoints',
      'networkError',
    ]),
    ...mapGettersTyped([
      'matchedZonesSet',
      'allSelectedAreasSet',
      'currentAssignment',
      'blockedAreas',
    ]),
    scaleX(): number {
      return this.width / this.naturalWidth
    },
    scaleY(): number {
      return this.height / this.naturalHeight
    },
    additionalTools(): Tool<AdditionalToolsNames>[] {
      return [
        {
          name: 'nodirection',
          icon: 'mdi-sign-direction-minus',
          label: `${this.$t('button.nodirection')}`,
          disabled: !(
            this.editingArea &&
            this.canI('UpdatePresetArea', this.editingArea) &&
            ![undefined, 'None'].includes(this.editingArea.directionType) &&
            !this.isBlocked(this.editingArea)
          ),
          action: this.setDirection,
        },
        {
          name: 'direction',
          icon: 'mdi-arrow-right',
          label: `${this.$t('button.direction')}`,
          disabled: !(
            this.editingArea &&
            this.canI('UpdatePresetArea', this.editingArea) &&
            this.editingArea.directionType !== 'Directed' &&
            !this.isBlocked(this.editingArea)
          ),
          action: this.setDirection,
        },
        {
          name: 'bidirectional',
          icon: 'mdi-arrow-left-right',
          label: `${this.$t('button.bidirectional')}`,
          disabled: !(
            this.editingArea &&
            this.canI('UpdatePresetArea', this.editingArea) &&
            this.editingArea.directionType !== 'Bidirectional' &&
            !this.isBlocked(this.editingArea)
          ),

          action: this.setDirection,
        },
        {
          name: 'delete',
          icon: 'mdi-delete',
          label:
            this.editingArea && this.blockedAreas.includes(this.editingArea.id)
              ? `${this.$t('message.areaBlocked')}`
              : `${this.$t('button.deleteArea')}`,
          disabled:
            !this.editingArea ||
            this.isBlocked(this.editingArea) ||
            !this.canI('DeletePresetArea', this.editingArea),
          action: () => {
            this.areaForDelete = this.editingArea
            this.displayDeleteDialog = true
          },
        },
      ]
    },
    drawTools(): Tool<DrawToolName>[] {
      return [
        {
          name: 'Line',
          icon: 'mdi-vector-line',
          label: `${this.$t('button.line')}`,
          disabled: !this.canI('CreatePresetArea'),
          action: this.setDrawTool,
        },
        {
          name: 'Polygon',
          icon: 'mdi-shape-polygon-plus',
          label: `${this.$t('button.zone')}`,
          disabled: !this.canI('CreatePresetArea'),
          action: this.setDrawTool,
        },
      ]
    },
    isEditable(): boolean {
      return !!(
        this.editingArea !== null &&
        !this.hiddenAreas.includes(this.editingArea.id) &&
        this.middlePointsMap[this.editingArea.id] &&
        (!this.blockedAreas.includes(this.editingArea.id) ||
          this.ignoredBlocking.includes(this.editingArea.id))
      )
    },
  },
  watch: {
    'currentAssignment.id': {
      handler() {
        this.setValue(['editingArea', null])
        this.setValue(['currentDrawTool', null])
        this.setValue(['currentDrawDirection', 'None'])
        this.ignoredBlocking = []
        this.initTransformer('')
      },
    },
    matchedZonesSet() {
      this.initMemoizes()
    },
    allAreas: {
      handler(val: IPresetArea[]) {
        val?.forEach((area) => {
          if (!(area.id in this.centroidsMap)) {
            this.reculcCentroid(area)
            this.reculcMiddlePoints(area)
          }
        })
      },
      immediate: true,
    },
  },
  mounted() {
    this.initMemoizes()
    document.addEventListener('keydown', this.putToDelete)
  },
  destroyed() {
    document.removeEventListener('keydown', this.putToDelete)
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
    ...mapActionsTyped(['deleteArea']),
    async deleteCurrentArea() {
      if (this.areaForDelete) {
        const result = await this.deleteArea(this.areaForDelete)
        !result &&
          this.errorToast({
            message: this.$t('errorMessages.zoneDeletingError'),
          })
      }
      this.displayDeleteDialog = false
    },
    absoluteToRelativeCoordinates(
      area: [number, number][],
    ): [number, number][] {
      return area
    },
    relativeToAbsoluteCoordinates(
      area: [number, number][],
    ): [number, number][] {
      return area.map(([x, y]) => [
        Math.round(x * this.naturalWidth),
        Math.round(y * this.naturalHeight),
      ])
    },
    setCursorPoiner(target: any) {
      target.getStage().container().style.cursor = 'pointer'
    },
    calcOffsets(area: IPresetArea['area']): [number, number, number, number] {
      const [minX, minY, maxX, maxY] = area.reduce(
        ([minX, minY, maxX, maxY], [x, y]) => {
          x = Math.floor(x * this.scaleX)
          y = Math.floor(y * this.scaleY)
          return [
            x < minX ? x : minX,
            y < minY ? y : minY,
            x > maxX ? x : maxX,
            y > maxY ? y : maxY,
          ]
        },
        [Infinity, Infinity, 0, 0],
      )
      // [topOffset, bottomOffset, leftOffset, rightOffset]
      return [-minY, this.height - maxY, -minX, this.width - maxX]
    },
    dragBoundFuncArea({ x, y }: { x: number; y: number }) {
      const [topOffset, bottomOffset, leftOffset, rightOffset] =
        this.canvasOffsets
      return {
        x: x > leftOffset ? (x >= rightOffset ? rightOffset : x) : leftOffset,
        y: y > topOffset ? (y >= bottomOffset ? bottomOffset : y) : topOffset,
      }
    },
    dragBoundFunc({ x, y }: { x: number; y: number }) {
      return {
        x: x > 0 ? (x >= this.width ? this.width : x) : 0,
        y: y > 0 ? (y >= this.height ? this.height : y) : 0,
      }
    },
    fillColorM: (area: IPresetArea) => {},
    strokeColorM: (area: IPresetArea) => {},
    sqrt: Math.sqrt,
    vecToAngle,
    putToDelete(event: KeyboardEvent) {
      const { key } = event
      if (
        key === 'Delete' &&
        this.editingArea &&
        this.canI('DeletePresetArea', this.editingArea) &&
        !this.blockedAreas.includes(this.editingArea.id)
      ) {
        this.areaForDelete = this.editingArea
        this.displayDeleteDialog = true
      }
    },
    dragAreaStart(area: IPresetArea) {
      this.canvasOffsets = this.calcOffsets(area.absoluteArea || [])
    },
    isBlocked({ id }: IPresetArea) {
      return (
        this.blockedAreas.includes(id) && !this.ignoredBlocking.includes(id)
      )
    },
    dragAreaEnd(event: any, area: IPresetArea) {
      const [topOffset, bottomOffset, leftOffset, rightOffset] =
        this.canvasOffsets
      let { x: x2, y: y2 } = event.target._lastPos
      x2 = x2 > leftOffset ? (x2 >= rightOffset ? rightOffset : x2) : leftOffset
      y2 = y2 > topOffset ? (y2 >= bottomOffset ? bottomOffset : y2) : topOffset
      area.absoluteArea = area.absoluteArea?.map(([x, y]) => {
        return [
          Math.floor(x + x2 / this.scaleX),
          Math.floor(y + y2 / this.scaleY),
        ]
      })
      area.area = this.absoluteToRelativeCoordinates(area.absoluteArea || [])
      this.reculcMiddlePoints(area)
      this.reculcCentroid(area)
      event.target.x(0)
      event.target.y(0)

      this.saveArea(area, {
        area: area.area,
      })
      this.setValue(['areas', [...this.allAreas]])
      event.target.getStage().container().style.cursor =
        this.currentDrawTool !== null ? 'crosshair' : 'grab'
    },
    // Чтобы не пересчитывать цвета каждый hover
    initMemoizes() {
      // @ts-ignore
      this.strokeColorM = memoize(this.strokeColor)
      // @ts-ignore
      this.fillColorM = memoize(this.fillColor)
    },
    strokeColor(area: IPresetArea) {
      if (this.isBlocked(area)) {
        return this.allSelectedAreasSet.includes(area.id)
          ? 'rgba(55, 197, 100, 1)'
          : 'rgba(64, 73, 81, 1)'
      }
      if (this.currentAssignment === null) {
        return '#3C72FF'
      }
      if (this.allSelectedAreasSet.includes(area.id)) {
        return 'rgba(55, 197, 100, 1)'
      }
      if (!this.matchedZonesSet.includes(area)) {
        return 'rgba(149, 157, 168, 0.6)'
      }
      return '#3C72FF'
    },
    fillColor(area: IPresetArea) {
      const opacity = [this.hoveredArea, this.editingArea].includes(area)
        ? '0.6'
        : '0.4'
      if (this.isBlocked(area)) {
        return this.allSelectedAreasSet.includes(area.id)
          ? `rgba(55, 197, 100, ${opacity})`
          : `rgba(64, 73, 81, ${opacity})`
      }
      if (this.currentAssignment === null) {
        return `rgba(60, 114, 255,${opacity}`
      }
      if (this.allSelectedAreasSet.includes(area.id)) {
        return `rgba(55, 197, 100, ${opacity})`
      }
      if (!this.matchedZonesSet.includes(area)) {
        return `rgba(149, 157, 168, ${opacity})`
      }
      return `rgba(60, 114, 255,${opacity}`
    },
    dropDraw(event: any) {
      event.evt.preventDefault()
      event.evt.stopPropagation()
      this.setValue(['currentDrawingPoints', []])
      this.setValue(['currentDrawTool', null])
      this.setValue(['currentDrawDirection', 'None'])
      return false
    },
    setDirection(tool: Tool<AdditionalToolsNames>) {
      if (!this.editingArea) return
      const area = { ...this.editingArea }
      this.$set(this.editingArea, 'directionType', directionMap[tool.name])
      this.$set(
        this.editingArea,
        'direction',
        tool.name !== 'nodirection'
          ? this.editingArea.direction || [1, 0]
          : undefined,
      )
      setTimeout(() => {
        if (!this.editingArea) return
        const { direction, directionType } = this.editingArea
        this.saveArea(area, { direction, directionType })
      })
    },
    setDrawTool(tool: Tool<DrawToolName>) {
      !tool.disabled && this.setValue(['currentDrawTool', tool.name])
    },
    reculcCentroid(area: IPresetArea) {
      this.$set(
        this.centroidsMap,
        area.id,
        calcCentroid(area.absoluteArea || []),
      )
    },
    reculcMiddlePoints(area: IPresetArea) {
      this.$set(
        this.middlePointsMap,
        area.id,
        Number(area?.absoluteArea?.length) > 2
          ? middlePoints(area.absoluteArea || [])
          : [],
      )
    },
    updateLastPoint(event: any) {
      const { x, y } = event.currentTarget.getPointerPosition()
      this.lastPoint = [x / this.scaleX, y / this.scaleY]
    },
    selectArea(area: IPresetArea, target?: any) {
      if (this.currentDrawTool) return
      if (this.isBlocked(area)) {
        this.displayEditBlocked = true
        this.ignoringCandidate = area.id
      } else {
        this.canI('UpdatePresetArea', this.editingArea) &&
          this.initTransformer(area.id)
        this.setEditing(area)

        if (target) {
          target.getStage().container().style.cursor =
            this.currentDrawTool !== null ? 'crosshair' : 'grabbing'
        }
      }
    },
    blurArea(event: any) {
      event.target.getStage().container().style.cursor =
        this.currentDrawTool !== null ? 'crosshair' : 'grab'
    },
    initTransformer(id: string) {
      const transformer = (
        this.$refs.transformer as Vue & { getNode: () => {} }
      )?.getNode() as any
      const stage = transformer.getStage()
      const selectedNode = stage.findOne('.' + id)
      if (selectedNode) {
        // attach to another node
        transformer.nodes([selectedNode])
      } else {
        // remove transformer
        transformer.nodes([])
      }
    },
    handleDrawClick(event: any) {
      if (this.currentDrawTool !== null) {
        const { x, y } = event.currentTarget.getPointerPosition()
        this.setValue([
          'currentDrawingPoints',
          [...this.currentDrawingPoints, x / this.scaleX, y / this.scaleY],
        ])
        this.currentDrawTool === 'Line' &&
          this.currentDrawingPoints.length === 4 &&
          this.closePolygon('Line')
      }
    },
    async closePolygon(areaType: IPresetArea['areaType']) {
      if (!this.assignmentGroup?.parameters?.presetId) return

      const area = [] as IPoint[]
      const points = [...this.currentDrawingPoints]

      while (points.length > 0) {
        const x = points.shift()
        const y = points.shift()
        x && y && area.push([x, y])
      }

      const presetArea: IPresetArea = {
        area,
        id: uuid(),
        title: nextName(`${this.$t(`areaType.${areaType}`)}`, this.allAreas),
        presetId: this.assignmentGroup.parameters.presetId,
        directionType: this.currentDrawDirection,
        type: 'PresetArea',
        areaType,
      }

      this.currentDrawDirection !== 'None' && (presetArea.direction = [1, 1])
      this.setValue(['currentDrawingPoints', []])
      this.setValue(['currentDrawTool', null])
      this.setValue(['currentDrawDirection', 'None'])

      try {
        const response = await createEntity<IPresetArea>(presetArea)

        if (!response) {
          throw new Error(this.$t('errorMessages.areaCreatingError') as string)
        }

        debounce(() => {
          const createdArea = this.$store.state.areas.at(-1)
          if (createdArea) this.selectArea(createdArea)
        })
      } catch (error) {
        console.error(error)
        this.errorToast(error)
      }
    },
    movePoint(
      area: IPresetArea | null,
      event: any,
      position: [number, number],
    ) {
      if (!area) return
      position[0] = Math.floor(event.target.attrs.x)
      position[1] = Math.floor(event.target.attrs.y)
      this.reculcCentroid(area)
      this.reculcMiddlePoints(area)

      this.saveArea(area, {
        area: this.absoluteToRelativeCoordinates(area.absoluteArea || []),
      })
      this.setValue(['areas', [...this.allAreas]])
    },
    rotationSave(event: any, area: IPresetArea) {
      const oldArea = { ...area }
      area.direction = angleToVec(event.target.rotation()) as [number, number]
      this.saveArea(oldArea, { direction: area.direction })
      this.setValue(['areas', [...this.allAreas]])
    },
    addPoint(area: IPresetArea | null, event: any, index: number) {
      if (!area) return
      area?.absoluteArea?.splice(index + 1, 0, [
        event.target.attrs.x,
        event.target.attrs.y,
      ])
      area.area = this.absoluteToRelativeCoordinates(area.absoluteArea || [])
      this.reculcCentroid(area)
      this.reculcMiddlePoints(area)
      this.saveArea(area, {
        area: area.area,
      })
      this.setValue(['areas', [...this.allAreas]])
      this.isDragging = false
    },
    deletePoint(area: IPresetArea | null, index: number) {
      if (!area) return
      area.absoluteArea?.splice(index, 1)
      area.area = this.absoluteToRelativeCoordinates(area.absoluteArea || [])
      this.reculcCentroid(area)
      this.reculcMiddlePoints(area)
      this.saveArea(area, {
        area: area.area,
      })
      this.setValue(['areas', [...this.allAreas]])
    },
    setHovered(target: any, area: IPresetArea) {
      target.getStage().container().style.cursor =
        this.currentDrawTool !== null ? 'crosshair' : 'grab'
      area && this.setValue(['hoveredArea', area])
    },
    clearHovered(target: any, area?: IPresetArea) {
      target.getStage().container().style.cursor =
        this.currentDrawTool !== null ? 'crosshair' : 'default'

      if (this.hoveredArea === area) {
        this.setValue(['hoveredArea', null])
      }
    },
    saveArea(area: IPresetArea, change: Partial<IPresetArea>) {
      debounce(
        async () => {
          try {
            delete area.absoluteArea
            const isSuccess = await updateEntity<IPresetArea>(area, change)

            if (!isSuccess) {
              throw new Error(
                this.$t('errorMessages.areasSavingError') as string,
              )
            }
          } catch (error) {
            console.error(error)
            this.errorToast(error)
          }
        },
        300,
        `area${area.id}`,
      )
    },
  },
})

import Vue from 'vue'
import { capitalize } from '@/utils'
export default Vue.extend({
  name: 'Te',
  data() {
    return {}
  },
  methods: {
    te(str: string, def: string) {
      return this.$te(str) ? this.$t(str) : capitalize(def)
    },
  },
})

import Vue from 'vue'
import { debounce, capitalize } from '@/utils'
import {
  createCamerasConnection,
  getPermissionsByIdsMap,
  getPermittedScopes,
} from '@netvision/lib-api-gateway'
import { mapStateTyped } from '@/store'
let batchQueue = [] as { id: Uuid; type: string }[]
let idsPermissionQueue = [] as string[]

export default Vue.extend({
  name: 'BatchQueue',
  data() {
    return {}
  },
  computed: {
    ...mapStateTyped(['idsMap', 'permissionScopes']),
  },
  methods: {
    async tryCatch(func: () => void, message?: string) {
      try {
        return await func()
      } catch (error) {
        console.error(error)
        this.errorToast(message ? { message } : error)
      }
    },
    te(str: string, def: string) {
      return this.$te(str) ? this.$t(str) : capitalize(def)
    },
    errorToast(error: any) {
      console.error(error)
      this.$toast.add({
        severity: 'error',
        summary: this.$t('error'),
        detail: error.message,
        life: 5000,
      })
    },
    putInBatchQueue(ids: { id: Uuid; type: string }[]) {
      const newIds = ids.filter(
        (e) =>
          !this.idsMap[e.id] && !batchQueue.find(({ id }: any) => id === e.id),
      )
      if (newIds.length > 0) {
        this.$store.commit('addLoadingRequest', 'updateEntity')
        batchQueue = [...batchQueue, ...newIds]
        debounce(
          async () => {
            try {
              if (batchQueue.length > 0) {
                const localBatch = [...batchQueue]
                batchQueue = []
                const {
                  results,
                }: {
                  results: {
                    id: Uuid
                    [k: string]: any
                  }[]
                } = await createCamerasConnection().v2.batchQuery(
                  {
                    entities: localBatch,
                  },
                  {
                    limit: 1000,
                    keyValues: true,
                  },
                )
                const idsMap = { ...this.idsMap } as any
                results.forEach((e) => {
                  idsMap[e.id] = e
                })
                this.$store.commit('setValue', ['idsMap', idsMap])
              }
            } catch (error) {
              this.errorToast(error)
            }
            this.$store.commit('deleteLoadingRequest', 'updateEntity')
          },
          200,
          'fetchBatch',
        )
      }
    },
    setEditing(area: IPresetArea) {
      this.$store.commit('setValue', ['editingArea', area])
    },
    canI(
      scope: string,
      entity: { id: string; resourceId: string } | null | any = null,
    ) {
      const scopes = this.permissionScopes.get(entity ? entity.id : scope)
      if (scopes) {
        return Array.isArray(scopes) ? scopes.includes(scope) : false
      }
      this.fetchPermissions(scope, entity)
      return false
    },
    async fetchPermissions(
      scope: string,
      entity: { id: string; resourceId?: string } | null = null,
    ) {
      const id = entity?.resourceId || entity?.id
      if (id) {
        idsPermissionQueue = Array.from(new Set([...idsPermissionQueue, id]))
        debounce(
          async () => {
            const permIds = (await getPermissionsByIdsMap(
              idsPermissionQueue,
            )) as Map<string, string[]>
            if (permIds?.size > 0) {
              this.$store.commit('addPermission', permIds)
              idsPermissionQueue = []
            }
          },
          200,
          'getScopes',
        )
      } else {
        debounce(
          async () => {
            if (this.permissionScopes.get(entity ? entity.id : scope)) return
            const result = await getPermittedScopes([scope])
            this.permissionScopes.set(scope, result)
            this.$store.commit('addPermission', [[scope, result]])
          },
          500,
          scope,
        )
      }
    },
  },
})

import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import Vuex from 'vuex'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner'
import FieldLabel from '@/components/common/FieldLabel.vue'
import AutoComplete from '@/components/common/AutoComplete.vue'
import VueI18n from 'vue-i18n'
import VueKonva from 'vue-konva'

import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import App from './App.vue'

Vue.use(Vuex)
Vue.use(VueKonva)
Vue.component('AutoComplete', AutoComplete)
Vue.component('FieldLabel', FieldLabel)
Vue.component('Toast', Toast)
Vue.use(ToastService)
Vue.directive('tooltip', Tooltip)
Vue.component('InputText', InputText)
Vue.component('Button', Button)
Vue.component('Dialog', Dialog)
Vue.component('Textarea', Textarea)
Vue.component('ProgressBar', ProgressBar)
Vue.component('Dropdown', Dropdown)
Vue.component('ProgressSpinner', ProgressSpinner)

Vue.use(VueI18n)

Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue()

const locale = 'ru'
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
}
export const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
})

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    i18n,
    render: (h: any) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount


import Vue from 'vue'
import EditorTemplate from '../common/EditorTemplate.vue'
import PresetPlayer from '../players/PresetPlayer.vue'
import { clearPosterVideoCahce } from '@/api'
import { mapStateTyped, mapMutationsTyped, mapActionsTyped } from '@/store'

export default Vue.extend({
  name: 'PresetEditor',
  components: {
    EditorTemplate,
    PresetPlayer,
  },
  data() {
    return {
      width: 1024,
      height: 800,
      naturalWidth: 1920,
      naturalHeight: 1080,
    }
  },
  computed: {
    ...mapStateTyped(['widgetProps']),
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
    ...mapActionsTyped(['fetchPresets']),
    closeEditor() {
      clearPosterVideoCahce()
      this.setValue(['editorMode', 'areas'])
    },
  },
})

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/inputtext"), require("primevue/calendar"), require("primevue/textarea"), require("primevue/inputswitch"), require("primevue/radiobutton"), require("primevue/inlinemessage"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("primevue/toastservice"), require("vue-i18n"), require("primevue/progressspinner"), require("vue"), require("primevue/inputnumber"), require("@netvision/lib-api-repo"), require("@netvision/lib-api-gateway"), require("primevue/checkbox"), require("primevue/multiselect"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/dialog"), require("primevue/button"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/inputtext", "primevue/calendar", "primevue/textarea", "primevue/inputswitch", "primevue/radiobutton", "primevue/inlinemessage", "primevue/dropdown", "primevue/tooltip", "single-spa-vue", "primevue/toastservice", "vue-i18n", "primevue/progressspinner", "vue", "primevue/inputnumber", "@netvision/lib-api-repo", "@netvision/lib-api-gateway", "primevue/checkbox", "primevue/multiselect", "primevue/toast", "primevue/progressbar", "primevue/dialog", "primevue/button"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/inputtext"), require("primevue/calendar"), require("primevue/textarea"), require("primevue/inputswitch"), require("primevue/radiobutton"), require("primevue/inlinemessage"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("primevue/toastservice"), require("vue-i18n"), require("primevue/progressspinner"), require("vue"), require("primevue/inputnumber"), require("@netvision/lib-api-repo"), require("@netvision/lib-api-gateway"), require("primevue/checkbox"), require("primevue/multiselect"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/dialog"), require("primevue/button")) : factory(root["primevue/inputtext"], root["primevue/calendar"], root["primevue/textarea"], root["primevue/inputswitch"], root["primevue/radiobutton"], root["primevue/inlinemessage"], root["primevue/dropdown"], root["primevue/tooltip"], root["single-spa-vue"], root["primevue/toastservice"], root["vue-i18n"], root["primevue/progressspinner"], root["vue"], root["primevue/inputnumber"], root["@netvision/lib-api-repo"], root["@netvision/lib-api-gateway"], root["primevue/checkbox"], root["primevue/multiselect"], root["primevue/toast"], root["primevue/progressbar"], root["primevue/dialog"], root["primevue/button"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__025a__, __WEBPACK_EXTERNAL_MODULE__0482__, __WEBPACK_EXTERNAL_MODULE__12bc__, __WEBPACK_EXTERNAL_MODULE__199d__, __WEBPACK_EXTERNAL_MODULE__1ae1__, __WEBPACK_EXTERNAL_MODULE__21f8__, __WEBPACK_EXTERNAL_MODULE__3746__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8aba__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__938d__, __WEBPACK_EXTERNAL_MODULE__9463__, __WEBPACK_EXTERNAL_MODULE_bb6b__, __WEBPACK_EXTERNAL_MODULE_bc44__, __WEBPACK_EXTERNAL_MODULE_be9f__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_d833__, __WEBPACK_EXTERNAL_MODULE_dec9__, __WEBPACK_EXTERNAL_MODULE_f095__) {
return 

import EditorTemplate from '../common/EditorTemplate.vue'
import PreviewPlayer from '../players/PreviewPlayer.vue'
import Canvas from '../canvas/Canvas.vue'
import Messages from '../common/Messages.vue'
import BatchQueue from '../common/BatchQueue'
import { mapGettersTyped, mapStateTyped, mapActionsTyped } from '@/store'
import { debounce } from '@/utils'

const MAX_ALLOWED_CHARS = 30

export default BatchQueue.extend({
  name: 'AreasEditor',
  components: {
    EditorTemplate,
    PreviewPlayer,
    Canvas,
    Messages,
  },
  data() {
    return {
      width: 1024,
      height: 800,
      naturalWidth: 1920,
      naturalHeight: 1080,
      renamingGroup: false,
      currentRenamingString: '',
    }
  },
  computed: {
    ...mapStateTyped([
      'widgetProps',
      'assignmentGroup',
      'errorPreview',
      'networkError',
    ]),
    ...mapGettersTyped(['currentAssignment']),
  },
  watch: {
    currentRenamingString(val: string) {
      if (val.length > MAX_ALLOWED_CHARS) {
        this.currentRenamingString = val.slice(0, MAX_ALLOWED_CHARS)
      }
    },
  },

  methods: {
    ...mapActionsTyped(['updateEntity']),
    renameGroup() {
      const title = this.currentRenamingString
      debounce(
        () =>
          this.tryCatch(
            () =>
              this.updateEntity([
                this.assignmentGroup,
                {
                  title,
                },
              ]),
            `${this.$t('errorMessages.renamingError')}`,
          ),
        300,
        'assignmentGroup',
      )
      this.renamingGroup = false
    },
    setRenaming() {
      this.renamingGroup = true
      this.currentRenamingString =
        this.assignmentGroup.title || `${this.$t('blocksHeaders.assignments')}`
      let input = this.$refs.assignmentGroupInput as HTMLInputElement
      input = Array.isArray(input) ? input[0] : input
      input && setTimeout(() => input.focus(), 100)
    },
  },
})

import InputField from '../fields/InputField.vue'
import NumberField from '../fields/NumberField.vue'
import EnumField from '../fields/EnumField.vue'
import ReferenceField from '../fields/ReferenceField.vue'
import ArrayField from '../fields/ArrayField.vue'
import ToggleField from '../fields/ToggleField.vue'
import PresetField from '../fields/PresetField.vue'
import URLField from '../fields/URLField.vue'
import DateField from '../fields/DateField.vue'
import StreamField from '../fields/StreamField.vue'

export default {
  string: InputField,
  number: NumberField,
  url: URLField,
  enum: EnumField,
  ref: ReferenceField,
  array: ArrayField,
  boolean: ToggleField,
  preset: PresetField,
  date: DateField,
  stream: StreamField,
}

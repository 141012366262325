
import EditorTemplate from '../common/EditorTemplate.vue'
import PreviewPlayer from '../players/PreviewPlayer.vue'
import Calibration from '../canvas/Calibration.vue'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import BatchQueue from '../common/BatchQueue'
import { debounce } from '@/utils'
import { updateEntity } from '@/api'
import { mapStateTyped } from '@/store'

export default BatchQueue.extend({
  name: 'CalibrationEditor',
  components: {
    EditorTemplate,
    PreviewPlayer,
    Calibration,
    InputSwitch,
    InputNumber,
  },
  data() {
    return {
      width: 1024,
      height: 800,
      naturalWidth: 1920,
      naturalHeight: 1080,
      showGrid: true,
      showCalibration: false,
      notSavedGroundCalibration: null as GroundCalibration | null,
      defaultGroundCalibration: {
        rectangleHeight: 10,
        rectangleWidth: 10,
        projectionMatrix: [],
        rectanglePoints: [
          { x: 0.33, y: 0.25 },
          { x: 0.66, y: 0.25 },
          { x: 0.8, y: 0.8 },
          { x: 0.2, y: 0.81 },
        ],
      },
    }
  },
  computed: {
    ...mapStateTyped(['assignmentGroup', 'presets', 'errorPreview']),
    preset(): ICameraPreset | { groundCalibration: null } {
      const { presetId } = this.assignmentGroup.parameters || {
        presetId: false,
      }
      if (presetId) {
        const preset = this.presets?.find(({ id }) => id === presetId)
        return preset
          ? {
              ...preset,
              groundCalibration:
                preset.groundCalibration &&
                (
                  Object.keys(this.defaultGroundCalibration) as Array<
                    keyof GroundCalibration
                  >
                ).every((key) => preset.groundCalibration[key])
                  ? preset.groundCalibration
                  : null,
            }
          : {
              groundCalibration: null,
            }
      }
      return { groundCalibration: null }
    },
    groundCalibration: {
      set(val: GroundCalibration) {
        this.notSavedGroundCalibration = val
      },
      get(): GroundCalibration {
        return (
          this.notSavedGroundCalibration ||
          this.preset.groundCalibration ||
          this.defaultGroundCalibration
        )
      },
    },
  },
  methods: {
    saveCalibration() {
      const preset = { ...this.preset } as IPreset
      debounce(
        async () => {
          if (!this.notSavedGroundCalibration) return
          this.$store.commit('setValue', [
            'presets',
            this.presets?.map((pres) =>
              pres.id === preset.id
                ? {
                    ...preset,
                    groundCalibration: this.notSavedGroundCalibration,
                  }
                : pres,
            ),
          ])
          this.$eventBus.$emit('updatePresets')
          try {
            const isSuccess = await updateEntity<IPreset>(preset, {
              groundCalibration: this.notSavedGroundCalibration,
            })

            if (!isSuccess) {
              throw new Error(
                this.$t('errorMessages.calibrationError') as string,
              )
            }

            this.notSavedGroundCalibration = null
          } catch (error) {
            console.error(error)
            this.errorToast(error)
          }
        },
        200,
        `preset${preset.id}`,
      )
      this.$store.commit('setValue', ['editorMode', 'areas'])
    },
  },
})

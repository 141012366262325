/* eslint-disable no-use-before-define */
import {
  listEntities,
  createCamerasConnection,
  getSharedNotificationSocket,
} from '@netvision/lib-api-gateway'
import { updateEntity, deleteEntity, createEntity } from '@/api'
import { uuid, nextName, tryCatchThrow, debounce } from '@/utils'
import { merge, memoize, isEqual, assign } from 'lodash'
import { TUnionRepo } from '@netvision/lib-api-repo'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { i18n } from '@/main'
import { COTApi } from '@/COT'

const initialState = () =>
  ({
    complexObjectTree: {} as ComplexObjectTree,
    treeChildren: [] as IEntity[],
    api: {} as TUnionRepo,
    loading: false as boolean,
    loadingRequests: [] as string[],
    idsMap: {} as Record<string, any>,
    streams: [] as Stream[],
    presets: null as (ICameraPreset | IPreset)[] | null,
    assignmentGroup: {} as IAssignmentGroup,
    areas: [] as IPresetArea[],
    areasLoadStatus: 'init' as FetchingStatus,
    errorPreview: false as boolean,
    allEntityAssignments: [] as IAssignment[],
    editorMode: 'areas' as EditorModes,
    editingArea: null as IPresetArea | null,
    hoveredArea: null as IPresetArea | null,
    widgetProps: {} as IWidgetProps,
    propertiesMap: {} as Record<string, IAssignmentParameters>,
    hiddenAreas: [] as IPresetArea['id'][],
    disabledAreas: [] as IPresetArea['id'][],
    currentAssignmentId: null as Uuid | null,
    assignmentTypes: {} as Record<string, IAssignmentType>,
    strictRegEx: /[&?#<>"'=;()]/,
    maxChars: 25,
    camera: null as ICameraWithPTZ | null,
    permissionScopes: new Map() as Map<string, string[]>,
    subscriptions: [] as (() => void)[],
    matchedZones: {} as Record<string, any>,
    zonesRequirements: {} as Record<string, any>,
    videoStore: {} as Record<string, string>,
    currentDrawTool: null as IPresetArea['areaType'] | null,
    currentDrawDirection: 'None' as IPresetArea['directionType'],
    currentDrawingPoints: [] as number[],
    networkError: false,
    errorsState: {} as Record<
      string,
      {
        type: IAssignmentType['title']
        state: {
          vfjsErrors: SchemaError[]
          [key: string]: { vfjsFieldErrors: {}[] } | SchemaError[]
        }
      }
    >,
    allSelectedAreas: {} as Record<string, any>,
  } as const)

type State = ReturnType<typeof initialState>

const doubleFlattenSet = (record: Record<string, any[]>): any[] => {
  return Array.from(new Set(Object.values(record).flat()))
}

const getters = {
  // геттеры не кешируется при использовании в других геттерах
  assignments: memoize(
    (state: State): IAssignment[] => {
      return state.allEntityAssignments.filter(
        ({ groupId }) => groupId === state.assignmentGroup?.id,
      )
    },
    (state: State) => state.allEntityAssignments,
  ),
  loading(state: State): boolean {
    return state.loadingRequests.length !== 0
  },
  rejectStatuses(_: State, { assignments }: { assignments: IAssignment[] }) {
    return assignments.reduce((acc, { id, rejectReason }) => {
      rejectReason && (acc[id] = rejectReason)
      return acc
    }, {} as Record<string, string>)
  },
  areasFieldsNames: memoize(
    (state: State) => {
      return Object.values(state.propertiesMap).reduce(
        (acc, { viewEditor }) => {
          const areasFields = Object.values(viewEditor?.fields || {})
            ?.filter(({ component }) => component === 'area')
            ?.map(({ model }: UISchema) => model)
          return Array.isArray(areasFields)
            ? Array.from(new Set([...(acc as string[]), ...areasFields]))
            : acc
        },
        [] as string[],
      )
    },
    (state: State) => state.propertiesMap,
  ),
  blockedAreas(state: State, getters: { areasFieldsNames: string[] }) {
    return state.allEntityAssignments.reduce((acc, { parameters }) => {
      const values = Object.entries(parameters)
        .filter(([key, val]) =>
          getters.areasFieldsNames.includes(key) ? val : false,
        )
        .map(([_, val]) => val) as string[]

      if (Array.isArray(values)) {
        acc = Array.from(new Set([...acc, ...values.flat()]))
      }
      return acc
    }, [] as string[])
  },
  currentAssignment(
    state: State,
    { assignments }: { assignments: IAssignment[] },
  ) {
    return (
      assignments.find(({ id }) => id === state.currentAssignmentId) || null
    )
  },
  assignmnentIdsWithErrors(state: State) {
    return (Object.entries(state.errorsState) as [Uuid, any]).reduce(
      (acc, [key, errors]) => {
        errors.state.vfjsErrors.length > 0 && acc.push(key)
        return acc
      },
      [] as Uuid[],
    )
  },
  matchedZonesSet(state: State) {
    const areas = state.matchedZones?.[state.currentAssignmentId || '']
    return areas ? doubleFlattenSet(areas) : []
  },
  allSelectedAreasSet(state: State) {
    if (!state.currentAssignmentId) return []
    const areas = state.allSelectedAreas[state.currentAssignmentId]
    return areas ? doubleFlattenSet(areas) : []
  },
  presetType(state: State) {
    return state.widgetProps.entityType === 'Camera' ? 'CameraPreset' : 'Preset'
  },
  presetIdField(state: State) {
    return state.widgetProps.entityType === 'Camera' ? 'cameraId' : 'entityId'
  },
  presetAreaRelationsMap(
    state: State,
  ): Record<IPresetArea['id'], { source: IEntity['id']; id: string }> {
    const { relations } = state.complexObjectTree
    if (relations && 'presetArea' in relations) {
      // @ts-ignore
      return relations.presetArea.reduce((acc, { target, source, id }) => {
        acc[target.id] = { source: source.id, id }
        return acc
      }, {} as Record<IPresetArea['id'], { source: IEntity['id']; id: string }>)
    }
    return {}
  },
}
type Getters = typeof getters

const mutations = {
  setValue<T extends keyof State>(state: State, [key, value]: [T, State[T]]) {
    state[key] = value
  },
  reset(state: State) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  },
  addLoadingRequest(state: State, requestName: string) {
    const loadingRequests = [...state.loadingRequests]
    loadingRequests.push(requestName)
    // @ts-ignore
    state.loadingRequests = loadingRequests
  },
  deleteLoadingRequest(state: State, requestName: string) {
    const loadingRequests = [...state.loadingRequests].filter(
      (e) => e !== requestName,
    )
    // @ts-ignore
    state.loadingRequests = loadingRequests
  },
  addPermission(state: State, scopes: [string, string[]][]) {
    // @ts-ignore
    state.permissionScopes = new Map([...state.permissionScopes, ...scopes])
  },
  destroySubscriptions(state: State) {
    state.subscriptions.forEach((e) => e())
  },
  appendAssignment(state: State, assignment: IAssignment) {
    state.allEntityAssignments.push(assignment)
  },
  assignAssignment(state: State, assignment: IAssignment) {
    const targetAssignment = state.allEntityAssignments.find(
      (el) => el.id === assignment.id,
    )
    if (targetAssignment) {
      targetAssignment.parameters = assignment.parameters
    }
  },
  ejectAssignment(state: State, id: string) {
    const targetAssignmentIndex = state.allEntityAssignments.findIndex(
      (el) => el.id === id,
    )

    if (targetAssignmentIndex > -1) {
      state.allEntityAssignments.splice(targetAssignmentIndex, 1)
    }
  },
}

type Mutations = typeof mutations

const aggregator = new Map() as Map<string, {}>

type ActionArguments = {
  commit: <K extends keyof Mutations>(
    method: keyof Mutations,
    payload: Parameters<Mutations[K]>[1],
  ) => void
  state: State
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
  dispatch: <T extends keyof Actions>(
    action: T,
    // @ts-ignore
    payload?: Parameters<Actions[T]>[1],
  ) => PromiseLike<unknown> | void
}

const actions = {
  async fetchStreams({
    state: { api, widgetProps },
    commit,
  }: ActionArguments): Promise<Stream[] | undefined> {
    if (!widgetProps?.entityId) return
    try {
      const { results } = (await api?.cubeGetEntities?.({
        dimensions: ['Stream.id', 'Stream.title', 'Stream.streamType'],
        filters: [
          {
            member: 'Stream.cameraId',
            operator: 'equals',
            values: [widgetProps?.entityId],
          },
        ],
      })) as {
        results: Stream[]
      }
      results && commit('setValue', ['streams', results])
      return results
    } catch (error) {
      console.error(error)
    }
  },
  updateEntity<T extends IEntity>(
    { state }: ActionArguments,
    [prev, change]: [T, Partial<T>],
  ) {
    return !state.networkError && updateEntity<T>(prev, change)
  },
  async deleteRelation(
    { state, dispatch, getters }: ActionArguments,
    area: IPresetArea,
  ) {
    dispatch('updateEntity', [
      area,
      {
        hasRelation: false,
      },
    ])
    const { id } = getters.presetAreaRelationsMap[area.id] || { id: false }
    if (!id) return
    await COTApi.deleteRelationFromTree({
      relationName: 'presetArea',
      treeId: state.complexObjectTree.id,
      relationId: id,
    })
  },
  async deleteArea(
    { state, commit, dispatch }: ActionArguments,
    area: IPresetArea,
  ) {
    await dispatch('deleteRelation', area)
    const result = await deleteEntity<IPresetArea>(area)
    if (result) {
      const areas: IPresetArea[] = state.areas
      if (areas?.length) {
        commit('setValue', ['areas', [...areas.filter((a) => a !== area)]])
        commit('setValue', ['editingArea', null])
      }
      return true
    }
    return false
  },
  setCurrentAssignment(
    { getters, commit }: ActionArguments,
    assignment: IAssignment | null,
  ) {
    const value =
      getters.currentAssignment === assignment ? null : assignment?.id || null
    commit('setValue', ['currentAssignmentId', value])
  },
  commitCommand(_: ActionArguments, [entity, command]: [IEntity, string]) {
    const { id, type } = entity
    return updateEntity(
      { ...entity, [command]: null },
      // @ts-ignore
      {
        id,
        type,
        // @ts-ignore
        [command]: {},
      },
    )
  },
  deleteEntity(_: ActionArguments, entity?: IEntity | null) {
    return entity ? deleteEntity(entity) : () => {}
  },
  async createAssignment(
    { commit, state, dispatch, getters }: ActionArguments,
    { title, id }: IAssignmentType,
  ) {
    const { entityId, entityType } = state.widgetProps

    if (!state.assignmentGroup.id) {
      const assignmentGroup = {
        id: uuid(),
        entityId,
        entityType,
        title: i18n.t('blocksHeaders.assignments'),
        type: 'AssignmentGroup',
      } as IAssignmentGroup
      await createEntity(assignmentGroup)
      commit('setValue', ['assignmentGroup', assignmentGroup])
    }

    const newAssignment = {
      id: uuid(),
      title: nextName(title, getters.assignments),
      assignmentTypeId: id,
      type: 'Assignment',
      groupId: `${state.assignmentGroup.id}`,
      parameters: {},
    } as IAssignment
    try {
      commit('appendAssignment', newAssignment)
      await createEntity(newAssignment)
    } catch (error) {
      commit('ejectAssignment', newAssignment?.id)
      if (getters.assignments.length === 0) {
        await tryCatchThrow(
          () => dispatch('deleteEntity', state.assignmentGroup),
          `${i18n.t('errorMessages.assignmentGroupDeletingError')}`,
        )
        commit('setValue', ['assignmentGroup', {}])
        dispatch('setCurrentAssignment', null)
      }
      throw error
    }
    dispatch('setCurrentAssignment', newAssignment)
  },
  async deleteAssignment(
    { state, dispatch, commit, getters }: ActionArguments,
    assignment: IAssignment,
  ) {
    try {
      commit('ejectAssignment', assignment?.id)
      await tryCatchThrow(
        () => dispatch('deleteEntity', assignment),
        `${i18n.t('errorMessages.assignmentDeletingError')}`,
      )
    } catch (error) {
      commit('appendAssignment', assignment)
      throw error
    }

    const allEntityAssignments = state.allEntityAssignments.filter(({ id }) =>
      assignment ? id !== assignment.id : true,
    )

    commit('setValue', ['allEntityAssignments', allEntityAssignments])

    assignment === getters.currentAssignment &&
      getters.assignments.length > 0 &&
      dispatch('setCurrentAssignment', getters.assignments[0])

    if (getters.assignments.length === 0) {
      await tryCatchThrow(
        () => dispatch('deleteEntity', state.assignmentGroup),
        `${i18n.t('errorMessages.assignmentGroupDeletingError')}`,
      )

      commit('setValue', ['assignmentGroup', {}])
      dispatch('setCurrentAssignment', null)
    }
  },
  async updateAssignment(
    { commit, state }: ActionArguments,
    assignment: IAssignment,
  ) {
    const { id } = assignment
    const aggregated = {
      ...(aggregator.get(id) || {}),
      ...assignment.parameters,
    }
    aggregator.set(id, aggregated)
    !state.networkError &&
      debounce(
        () => {
          const parameters = aggregator.get(id) as IAssignment
          tryCatchThrow(
            () => updateEntity(assignment, { parameters }),
            `${i18n.t('errorMessages.assignmentUpdatingError')}`,
          )
          aggregator.delete(id)
        },
        100,
        `assignment_${id}`,
      )
    commit('assignAssignment', { ...assignment, parameters: aggregated })
  },
  updateAssignmentGroup(
    { commit }: ActionArguments,
    assignmentGroup: IAssignmentGroup,
  ) {
    const { id } = assignmentGroup
    const aggregated = aggregator.get(id) || {}
    aggregator.set(id, { ...aggregated, ...assignmentGroup.parameters })
    debounce(
      () => {
        const parameters = aggregator.get(id) as IAssignmentGroup
        tryCatchThrow(
          () =>
            updateEntity<IAssignmentGroup>(assignmentGroup, {
              parameters,
            }),
          `${i18n.t('errorMessages.assignmentGroupUpdatingError')}`,
        )
        aggregator.delete(id)
        commit('setValue', [
          'assignmentGroup',
          { ...assignmentGroup, parameters },
        ])
      },
      100,
      'assignmentGroup',
    )
  },
  async fetchAssignmentGroupAndAssignments(
    { commit, state, dispatch }: ActionArguments,
    { i18n }: { i18n: any },
  ) {
    try {
      commit('addLoadingRequest', 'assignments')
      const { results: assignmentTypes } = await listEntities<IAssignmentType>(
        createCamerasConnection(),
        {
          type: 'AssignmentType',
          limit: 1000,
        },
      )
      dispatch('fetchPresets')
      if (assignmentTypes.length) {
        // fetch properties entities
        const entities = assignmentTypes.reduce(
          (acc: any[], { parametersId, groupParametersId }) => {
            parametersId &&
              acc.push({ id: parametersId, type: 'AssignmentParameters' })
            groupParametersId &&
              acc.push({ id: groupParametersId, type: 'AssignmentParameters' })
            return acc
          },
          [],
        )
        const {
          results: properties,
        }: {
          results: IAssignmentParameters[]
        } = await createCamerasConnection().v2.batchQuery(
          {
            entities,
          },
          {
            limit: 1000,
            keyValues: true,
          },
        )
        if (properties) {
          const propertiesMap = properties.reduce(
            (acc: Record<string, any>, cur) => {
              // merge props for entity type
              acc[cur.id] = merge(
                cur,
                cur.entityTypeParameters[
                  state.widgetProps.entityType as EntityTypes
                ],
              )
              return acc
            },
            {},
          )
          commit('setValue', ['propertiesMap', propertiesMap])
          commit('setValue', [
            'assignmentTypes',
            assignmentTypes.reduce((acc: Record<string, any>, cur) => {
              // Merge locales
              const locales = propertiesMap?.[cur.parametersId]?.localeRu
              // @ts-ignore
              i18n.vm.messages[i18n.locale] = {
                ...i18n.messages[i18n.locale],
                assignments: {
                  // @ts-ignore
                  ...i18n.messages[i18n.locale]?.assignments,
                  [cur.name]: locales,
                  common: merge(
                    // @ts-ignore
                    i18n.messages[i18n.locale]?.assignments?.common,
                    propertiesMap?.[cur.groupParametersId]?.localeRu,
                  ),
                },
              }
              acc[cur.id] = cur
              return acc
            }, {}),
          ])
        }
      }
      if (state.widgetProps.entityType === 'Camera') {
        try {
          const { results: cameras } = await listEntities<ICameraWithPTZ>(
            createCamerasConnection(),
            {
              id: state.widgetProps.entityId,
              type: 'Camera',
              attrs: 'status,ptzSettings',
            },
          )
          commit('setValue', ['camera', cameras[0]])

          // fetch camera complexObjectTree
          const { results: complexObjectTrees } =
            await listEntities<ComplexObjectTree>(createCamerasConnection(), {
              type: 'ComplexObjectTree',
              q: `flattenedChilds==${state.widgetProps.entityId}`,
            })
          const complexObjectTree = complexObjectTrees[0]
          if (complexObjectTree) {
            commit('setValue', ['complexObjectTree', complexObjectTree])
            const { childs } = complexObjectTree as ComplexObjectTree
            function flatChilds(childs: TreeChilds) {
              let acc = [] as IdType[]
              for (const child of childs) {
                acc = [
                  ...acc,
                  { id: child.id, type: child.type },
                  ...(child.childs ? flatChilds(child.childs) : []),
                ]
              }
              return acc
            }
            const flattenedChildsWithTypes = flatChilds(childs)

            setTimeout(async () => {
              if (!flattenedChildsWithTypes.length) return
              try {
                const {
                  results: entitites,
                }: {
                  results: IAssignmentParameters[]
                } = await createCamerasConnection().v2.batchQuery(
                  {
                    entities: flattenedChildsWithTypes,
                  },
                  {
                    limit: 1000,
                    keyValues: true,
                  },
                )
                commit('setValue', ['treeChildren', entitites])
              } catch (error) {
                console.error(error)
              }
            })
          }
        } catch (error) {
          console.error(error)
        }
      }
      // fetch assignmetGroup and belonging assignments
      if (state.widgetProps.assignmentGroupId) {
        try {
          const { entity: assignmentGroup } =
            await createCamerasConnection().v2.getEntity({
              id: state.widgetProps.assignmentGroupId,
              type: 'AssignmentGroup',
              keyValues: true,
            })
          if (assignmentGroup) {
            commit('setValue', ['assignmentGroup', assignmentGroup])
            const { results: assignments } = await listEntities<IAssignment>(
              createCamerasConnection(),
              {
                type: 'Assignment',
                q: `entityId==${state.widgetProps.entityId}`,
                limit: 1000,
              },
            )
            commit('setValue', ['allEntityAssignments', assignments])
          }
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      commit('setValue', ['networkError', true])
      throw new Error()
    } finally {
      commit('deleteLoadingRequest', 'assignments')
    }
  },
  async fetchPresets({
    commit,
    state,
    getters: { presetType, presetIdField },
  }: ActionArguments) {
    try {
      commit('addLoadingRequest', 'presets')
      const { results: presets } = await listEntities<ICameraPreset | IPreset>(
        createCamerasConnection(),
        {
          type: presetType,
          limit: 1000,
          q: `${presetIdField}==${state.widgetProps.entityId}`,
        },
      )
      presets && commit('setValue', ['presets', presets])
    } catch (error) {
      commit('setValue', ['networkError', true])
      throw new Error()
    } finally {
      commit('deleteLoadingRequest', 'presets')
    }
  },
  async fetchAreas({ commit }: ActionArguments, presetId: string) {
    try {
      commit('addLoadingRequest', 'areas')
      commit('setValue', ['editingArea', null])
      commit('setValue', ['areasLoadStatus', 'loading'])
      if (presetId) {
        const { results: presetAreas } = await listEntities<IPresetArea>(
          createCamerasConnection(),
          {
            type: 'PresetArea',
            q: `presetId==${presetId}`,
            limit: 1000,
          },
        )
        presetAreas && commit('setValue', ['areas', presetAreas])
        commit('setValue', [
          'areasLoadStatus',
          presetAreas.length ? 'complete' : 'empty',
        ])
      }
    } catch (_) {
      commit('setValue', ['networkError', true])
      commit('setValue', ['areasLoadStatus', 'error'])
      throw new Error()
    } finally {
      commit('deleteLoadingRequest', 'areas')
    }
  },
  createSubscriptions({
    commit,
    state,
    getters: { presetType, presetIdField },
  }: ActionArguments) {
    commit('setValue', [
      'subscriptions',
      [
        getSharedNotificationSocket().addListener(
          'Assignment',
          (entity: IAssignment) => {
            const allEntityAssignments = [...state.allEntityAssignments]
            const entityIndex = allEntityAssignments.findIndex(
              ({ id }) => entity.id === id,
            )
            if (entityIndex !== -1) {
              !isEqual(allEntityAssignments[entityIndex], entity) &&
                allEntityAssignments.splice(entityIndex, 1, entity) &&
                commit('setValue', [
                  'allEntityAssignments',
                  allEntityAssignments,
                ])
            } else if (entity.entityId === state.widgetProps.entityId) {
              allEntityAssignments.push(entity)
              commit('setValue', ['allEntityAssignments', allEntityAssignments])
            }
          },
        ),
        getSharedNotificationSocket().addListener(
          'AssignmentGroup',
          (entity: IAssignmentGroup) => {
            if (entity.id === state.assignmentGroup.id) {
              commit('setValue', ['assignmentGroup', entity])
            }
          },
        ),
        getSharedNotificationSocket().addListener(
          'ComplexObjectTree',
          (entity: ComplexObjectTree) => {
            if (entity.id === state.complexObjectTree.id) {
              commit('setValue', ['complexObjectTree', entity])
            }
          },
        ),
        getSharedNotificationSocket().addListener(
          'PresetArea',
          (entity: IPresetArea) => {
            const areas = [...state.areas]
            const entityIndex = areas.findIndex(({ id }) => entity.id === id)
            if (entityIndex !== -1) {
              assign(areas[entityIndex], entity)
              commit('setValue', ['areas', areas])
            } else if (
              state.assignmentGroup?.parameters?.presetId === entity.presetId
            ) {
              areas.push(entity)
              commit('setValue', ['areas', areas])
            }
          },
        ),
        getSharedNotificationSocket().addListener(
          presetType,
          (entity: ICameraPreset | IPreset) => {
            if (!state.presets) return
            const presets = [...state.presets]
            const entityIndex = presets.findIndex(({ id }) => entity.id === id)
            if (entityIndex !== -1) {
              presets[entityIndex] = entity
              commit('setValue', ['presets', presets])
            } else if (state.widgetProps.entityId === entity[presetIdField]) {
              presets.push(entity)
              commit('setValue', ['presets', presets])
            }
          },
        ),
      ],
    ])
  },
}

type Actions = typeof actions

export const mapStateTyped = <
  T extends keyof State,
  G extends { [Key in T]: () => State[Key] },
>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

export const mapMutationsTyped = <
  T extends keyof Mutations,
  G extends {
    [K in T]: (I?: Parameters<Mutations[K]>[1]) => ReturnType<Mutations[K]>
  },
>(
  keys: T[],
): G => {
  return mapMutations(keys) as G
}

export const mapGettersTyped = <
  T extends keyof Getters,
  G extends { [Key in T]: () => ReturnType<Getters[Key]> },
>(
  keys: T[],
): G => {
  return mapGetters(keys) as G
}

export const mapActionsTyped = <
  T extends keyof Actions,
  G extends {
    // @ts-ignore
    [Key in T]: (I?: Parameters<Actions[Key]>[1]) => ReturnType<Actions[Key]>
  },
>(
  keys: T[],
): G => {
  return mapActions(keys) as G
}

export default () => ({
  state: initialState(),
  mutations,
  getters,
  actions,
})


import FieldsBlock from '../common/FieldsBlock.vue'
import YesNoDialog from '../common/YesNoDialog.vue'
import StatusButtons from '../common/StatusButtons.vue'
import {
  mapStateTyped,
  mapGettersTyped,
  mapMutationsTyped,
  mapActionsTyped,
} from '@/store'
import BatchQueue from '../common/BatchQueue'
import { debounce } from '@/utils'

const MAX_ALLOWED_CHARS = 64

export default BatchQueue.extend({
  name: 'AssignmentsBlock',
  components: {
    FieldsBlock,
    YesNoDialog,
    StatusButtons,
  },
  data() {
    return {
      renamingGroup: false,
      showContent: true,
      displayDeleteDialog: false,
      currentRenaming: null as IAssignment | null,
      currentRenamingString: '',
      assignmentForDelete: null as IAssignment | null,
    }
  },
  computed: {
    ...mapStateTyped([
      'assignmentTypes',
      'allEntityAssignments',
      'strictRegEx',
      'widgetProps',
      'propertiesMap',
      'assignmentGroup',
      'networkError',
    ]),
    ...mapGettersTyped([
      'currentAssignment',
      'assignmnentIdsWithErrors',
      'assignments',
      'loading',
    ]),
    assignmentsTypesOptions(): IAssignmentType[] {
      return Object.values(this.assignmentTypes)
    },
    allStatus(): string | undefined {
      return this.assignments.reduce((acc, { status }) => {
        if (acc === status) {
          return status
        } else {
          return undefined
        }
      }, this.assignments[0].status as string | undefined)
    },
    isEachAssignmentCanStart(): boolean {
      return this.assignments.every(({ canStart }) => canStart)
    },
    isSomeAssignmentStarted(): boolean {
      return this.assignments.some(({ status }) => status === 'Started')
    },
    isEachAssignmentStarted(): boolean {
      return this.assignments.every(({ status }) => status === 'Started')
    }
  },
  watch: {
    currentRenamingString(val) {
      this.currentRenamingString = val
        .replace(this.strictRegEx, '')
        .slice(0, MAX_ALLOWED_CHARS)
    },
  },
  async beforeMount() {
    this.$store.dispatch('fetchAssignmentGroupAndAssignments', {
      i18n: this.$i18n,
    })
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
    ...mapActionsTyped([
      'commitCommand',
      'updateEntity',
      'createAssignment',
      'deleteAssignment',
      'setCurrentAssignment',
    ]),
    setRenaming(assignment: IAssignment, index: number) {
      this.currentRenamingString = assignment.title
      this.currentRenaming = assignment
      let input = this.$refs[
        assignment.id || assignment.assignmentTypeId + index
      ] as HTMLInputElement
      input = Array.isArray(input) ? input[0] : input
      input && setTimeout(() => input.focus(), 100)
    },
    startAssignmentGroup() {
      this.assignmentGroup &&
        this.tryCatch(
          () => this.commitCommand([this.assignmentGroup, 'startCommand']),
          `${this.$t('errorMessages.assignmentGroupStartingError')}`,
        )
    },
    stopAssignmentGroup() {
      this.assignmentGroup &&
        this.tryCatch(
          () => this.commitCommand([this.assignmentGroup, 'stopCommand']),
          `${this.$t('errorMessages.assignmentGroupStopError')}`,
        )
    },
    rename() {
      if (
        this.currentRenaming &&
        this.currentRenamingString !== this.currentRenaming.title
      ) {
        const assignment = { ...this.currentRenaming }
        this.currentRenaming.title = this.currentRenamingString
        debounce(
          () =>
            this.tryCatch(
              () =>
                this.updateEntity([
                  assignment,
                  {
                    title: this.currentRenamingString,
                  },
                ]),
              `${this.$t('errorMessages.renamingError')}`,
            ),
          300,
          `assignment_${assignment.id}`,
        )
      }
      this.currentRenaming = null
    },
    renameGroup() {
      const title = this.currentRenamingString
      debounce(
        () =>
          this.tryCatch(
            () =>
              this.updateEntity([
                this.assignmentGroup,
                {
                  title,
                },
              ]),
            `${this.$t('errorMessages.renamingError')}`,
          ),
        300,
        'assignmentGroup',
      )
      this.renamingGroup = false
    },
    async deleteLocalAssignment() {
      this.displayDeleteDialog = false
      try {
        this.assignmentForDelete &&
          (await this.deleteAssignment(this.assignmentForDelete))
      } catch (error) {
        this.errorToast(error)
      }
      this.assignmentForDelete = null
    },
    sendToCreateAssignmnent({ value }: { value: IAssignmentType }) {
      this.tryCatch(() =>
        this.createAssignment(value).catch(() => {
          this.errorToast({
            message: this.$t('errorMessages.featuresLimitExceeded'),
          })
        }),
      )
    },
  },
})

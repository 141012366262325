
import { mapStateTyped } from '@/store'
import Vue from 'vue'

const commit = new CustomEvent('commit', {
  bubbles: true,
})

export default Vue.extend({
  name: 'InputField',
  props: {
    value: { type: String, default: undefined },
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {}
  },
  computed: {
    ...mapStateTyped(['strictRegEx']),
    localValue: {
      set(val: string) {
        const newVal = val.replace(this.strictRegEx, '')
        this.$emit('commit', newVal)
        this.$el.dispatchEvent(commit)
      },
      get(): string {
        return this.value
      },
    },
  },
  mounted() {
    this.value === undefined &&
      typeof this.options.default === 'string' &&
      setTimeout(() => (this.localValue = this.options.default))
  },
})


import { mapStateTyped } from '@/store'
import Vue from 'vue'

export default Vue.extend({
  name: 'FieldsBlock',
  props: {
    header: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showContent: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      /*
      This one need if you don't give showContent prop
      */
      internalShowContent: true,
    }
  },
  computed: {
    ...mapStateTyped(['networkError']),
    localShowContent: {
      set(val: boolean) {
        this.internalShowContent = val
        this.$emit('update:showContent', val)
      },
      get(): boolean {
        return this.showContent ?? this.internalShowContent
      },
    },
  },
})

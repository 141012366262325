
import Vue from 'vue'
import { getServerContext } from '@netvision/lib-api-gateway'
import { mapActionsTyped, mapGettersTyped, mapStateTyped } from '@/store'
import { fetchPosterVideo } from '@/api'
import ResizeObserver from 'resize-observer-polyfill'

const { gatewayPath } = getServerContext()

export default Vue.extend({
  name: 'PreviewPlayer',
  props: {
    label: String,
    width: { type: Number, default: 1024, required: true },
    height: { type: Number, default: 800, required: true },
    naturalWidth: { type: Number, default: 800, required: true },
    naturalHeight: { type: Number, default: 800, required: true },
  },
  data() {
    return {
      localLoading: true,
      showMainPreview: false,
      resizeObserver: {} as any,
      src: '' as string,
      cameraStreamUrl: '' as string,
      headers: {} as Record<string, string>,
      previewStyle: {} as Record<string, number | string>,
      streamUrl: '' as string,
    }
  },
  computed: {
    ...mapStateTyped([
      'api',
      'assignmentGroup',
      'widgetProps',
      'videoStore',
      'errorPreview',
      'streams',
      'presets'
    ]),
    ...mapGettersTyped(['loading']),
    previewSrc(): string {
      const { entityId, entityType } = this.widgetProps
      if (
        entityType === 'Camera' &&
        ![null, undefined, ''].includes(
          this.assignmentGroup?.parameters?.presetId,
        )
      ) {
        return `${gatewayPath}/cameras/v1/presets/${this.assignmentGroup?.parameters?.presetId}`
      }
      if (['File', 'Record'].includes(entityType || '')) {
        return `${gatewayPath}/cameras/v2/blob/${entityId}?type=${entityType}&mode=preview`
      }
      return ''
    }
  },
  watch: {
    presets: {
      async handler(_, prev) {
        prev && this.updatePreview(true)
      },
      deep: true
    },
    previewSrc() {
      this.cameraStreamUrl = ''
      this.showMainPreview = false
      this.updatePreview()
    }
  },
  async created() {
    if (!('getHeaders' in this.api)) {
      throw new Error('"getHeaders" method isn\'t implemented in api')
    }
    this.headers = await this.api.getHeaders()
    this.updatePreview()
  },
  mounted() {
    const video = this.$refs?.video as HTMLVideoElement
    const videoWrapper = this.$refs?.videoWrapper as HTMLVideoElement
    this.resizeObserver = new ResizeObserver(() => {
      const player = (
        Array.isArray(this.$refs.video) ? this.$refs.video[0] : this.$refs.video
      ) as HTMLVideoElement
      const { videoWidth, videoHeight } = player
      const { offsetWidth, offsetHeight } = videoWrapper
      if (
        videoWidth &&
        videoHeight &&
        videoWidth < offsetWidth &&
        videoHeight < offsetHeight
      ) {
        this.previewStyle =
          offsetWidth / offsetHeight > videoWidth / videoHeight
            ? { height: '100%' }
            : { width: '100%' }
      }
      this.$emit('update:naturalWidth', videoWidth)
      this.$emit('update:naturalHeight', videoHeight)
      this.$emit('update:width', player.offsetWidth)
      this.$emit('update:height', player.offsetHeight)
    })
    this.resizeObserver.observe(video)
  },
  destroyed() {
    this.resizeObserver.disconnect()
  },
  methods: {
    ...mapActionsTyped(['fetchStreams']),
    async updatePreview(isForced = false) {
      const { entityType } = this.widgetProps
      entityType === 'Camera'
        ? await this.updateCameraPreview(isForced)
        : await this.commonPreviewUpdate()
    },
    async commonPreviewUpdate() {
      try {
        this.localLoading = true
        this.src = (await fetchPosterVideo({
          headers: this.headers,
          posterUrl: this.previewSrc
        })) || ''
        this.$store.commit('setValue', ['errorPreview', false])
      } catch (error) {
        this.$store.commit('setValue', ['errorPreview', true])
      } finally {
        this.localLoading = false
      }
    },
    async updateCameraPreview(isForced?: boolean) {
      const previewSrc = this.previewSrc
      this.localLoading = true
      try {
        if (!this.cameraStreamUrl) {
          if (this.previewSrc) {
            this.cameraStreamUrl = this.previewSrc
          } else {
            const streams = await this.fetchStreams()
            if (streams) {
              const streamId = (
                streams.find(({ streamType }) => streamType === 'Main') ||
                streams[0] || { id: '' }
              ).id
              const { snapshotStreamUrl } =
                // @ts-ignore
                await this.api.getEntitiesWithGlobalBatch<Stream>(
                  {
                    id: streamId,
                    type: 'Stream',
                  },
                  ['snapshotStreamUrl'],
                )
              this.cameraStreamUrl = snapshotStreamUrl || ''
            }
          }
        }
        if (previewSrc === this.previewSrc) {
          this.src =
            (await fetchPosterVideo({
              headers: this.headers,
              posterUrl: this.cameraStreamUrl,
              isForced
            })) || ''
        }
        this.$store.commit('setValue', ['errorPreview', false])
      } catch (error) {
        console.error(error)
        this.$store.commit('setValue', ['errorPreview', true])
      } finally {
        this.localLoading = false
      }
    },
  },
})


import Vue from 'vue'
import InputNumber from 'primevue/inputnumber'

const commit = new CustomEvent('commit', {
  bubbles: true,
})
export default Vue.extend({
  name: 'NumberField',
  components: {
    InputNumber,
  },
  props: {
    value: { type: Number, default: undefined },
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {}
  },
  computed: {
    localValue: {
      set(val: number) {
        this.$el.dispatchEvent(commit)
        this.$emit('commit', val)
      },
      get(): number | null {
        return !Number.isNaN(Number(this.value)) ? Number(this.value) : null
      },
    },
  },
  mounted() {
    this.value === undefined &&
      typeof this.options.default === 'number' &&
      setTimeout(() => (this.localValue = this.options.default))
  },
})


import Vue from 'vue'
import { createCamerasConnection, listEntities } from '@netvision/lib-api-gateway'
import { debounce, insensitify } from '@/utils'

export default Vue.extend({
  name: 'AutoComplete',
  props: {
    value: { type: String, default: '' },
    entityType: { type: String, default: '' },
    labelField: { type: String, default: '' },
    showClear: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    q: { type: String, default: '' },
    contentClass: { type: String, default: '' },
    limit: { type: Number, default: 100 },
  },
  data() {
    return {
      loading: false,
      filter: true,
      valueOptions: [] as any[],
    }
  },
  computed: {
    filteredOptions(): any[] {
      return this.valueOptions.filter((val: any) => {
        if (typeof this.localValue === 'string') {
          return val[this.labelField] !== this.localValue
        } else if (Array.isArray(this.localValue)) {
          return !this.localValue.includes(val.id)
        }
        return false
      })
    },
    localValue: {
      set(val: string) {
        this.$emit('change', val)
        const ref = this.$refs[`${this._uid}_autocomplete`] as any
        if (ref?.filterValue) {
          ref.filterValue = ''
        }
      },
      get(): string | unknown[] {
        return this.value
      },
    },
  },
  methods: {
    async fetchFirst() {
      if (this.valueOptions.length === 0) {
        this.valueOptions = await this.fetchOptions()
      }
    },
    async fetchOptions(q = ''): Promise<any[]> {
      try {
        const options = {
          limit: this.limit,
          type: this.entityType,
        } as Record<string, any>
        if (this.q !== '') {
          options.q = this.q
        }
        if (q !== '') {
          options.q = `${this.q};${q}`
        }
        const { results } = await listEntities(createCamerasConnection(), options)
        results.forEach((result: any) => {
          if (result[this.labelField] === undefined) {
            result[this.labelField] = ''
          }
        })
        return results
      } catch (error) {
        console.error(error)
        return []
      }
    },
    onFilter({ value }: { value: string }) {
      this.loading = true
      debounce(
        async () => {
          try {
            let q
            if (value.length > 0) {
              q = `${this.labelField}~=${insensitify(value)}`
            }
            this.valueOptions = await this.fetchOptions(q || '')
          } catch (error) {
            console.error(error)
          }
          this.loading = false
        },
        200,
        `${this.entityType}_fetchOptions`,
      )
    },
  },
})

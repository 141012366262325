
import Vue from 'vue'
import Editor from './components/Editor.vue'
import Vuex from 'vuex'
import initialStore, { mapMutationsTyped } from './store'
import { repositoryGetter, TLibProp } from '@netvision/lib-api-repo'

export default Vue.extend({
  name: 'App',
  components: {
    Editor,
  },
  provide(): any {
    return {
      spaParent: this.$parent,
    }
  },
  data() {
    const props = (this.$parent?.props as IWidgetProps) || {}
    return {
      props,
    }
  },
  beforeCreate() {
    this.$store = new Vuex.Store(initialStore())
  },
  async beforeMount() {
    this.$store.commit('setValue', ['widgetProps', this.props])
    this.$store.commit('setValue', [
      'api',
      repositoryGetter({
        ...this.$parent?.props?.lib,
        mixins: ['cubejs'],
      } as TLibProp),
    ])
    this.$store.dispatch('createSubscriptions')
  },
  async mounted() {
    this.props?.assignmentId &&
      this.setValue(['currentAssignmentId', this.props?.assignmentId])
    this.props?.permissionScopes &&
      this.setValue(['permissionScopes', this.props?.permissionScopes])
  },
  beforeDestroy() {
    this.destroySubscriptions()
  },
  methods: {
    ...mapMutationsTyped(['setValue', 'destroySubscriptions']),
  },
})


import Vue from 'vue'
import { mapState } from 'vuex'

const commit = new CustomEvent('commit', {
  bubbles: true,
})

type Options = {
  label: string
  editable: boolean
  entityType: string
  labelField: string
  disabled: boolean
  q: string
}

export default Vue.extend({
  name: 'ReferenceField',
  props: {
    value: { type: String, default: '' },
    options: {
      type: Object as () => Options,
      default: (): Partial<Options> => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['idsMap']),
    localValue: {
      set(val: string) {
        this.$emit('commit', val)
        this.$el.dispatchEvent(commit)
      },
      get(): string {
        return this.value
      },
    },
  },
  methods: {
    update(val: string) {
      this.localValue = val
    },
  },
})


import InputSwitch from 'primevue/inputswitch'
import Te from '../common/Te'
const commit = new CustomEvent('commit', {
  bubbles: true,
})

export default Te.extend({
  name: 'ToggleField',
  components: {
    InputSwitch,
  },
  props: {
    value: { type: Boolean, default: undefined },
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {}
  },
  mounted() {
    this.value === undefined &&
      this.options.default !== undefined &&
      setTimeout(() => (this.localValue = this.options.default))
  },
  computed: {
    localValue: {
      set(val: boolean) {
        this.$emit('commit', val)
        this.$el.dispatchEvent(commit)
      },
      get(): boolean {
        return this.value
      },
    },
  },
})


import { PropType } from 'vue'
import FieldsBlock from '../common/FieldsBlock.vue'
// @ts-ignore
import VueFormJsonSchema from 'vue-form-json-schema'
import components from '../common/fieldsMap'
import {
  mapMutationsTyped,
  mapStateTyped,
  mapActionsTyped,
  mapGettersTyped,
} from '@/store'
import { merge } from 'lodash'
import BatchQueue from '../common/BatchQueue'

export default BatchQueue.extend({
  name: 'CommonPropertiesBlock',
  components: {
    FieldsBlock,
    VueFormJsonSchema,
  },
  props: {
    assignmentGroup: Object as PropType<IAssignmentGroup>,
  },
  data() {
    return {
      components,
      isValid: false as boolean,
      trigger: true,
      prevVal: {} as IAssignmentGroup['parameters'],
      aggregator: {} as IAssignmentGroup['parameters'],
      localParams:
        this.assignmentGroup.parameters ||
        ({} as IAssignmentGroup['parameters']),
    }
  },
  computed: {
    ...mapStateTyped(['assignmentTypes', 'propertiesMap', 'presets']),
    ...mapGettersTyped(['assignments']),
    options() {
      return {
        showValidationErrors: true,
        ajv: {
          keywords: {
            calibrationRequired: {
              errors: true as any,
              validate: (_: any, value: any): boolean => {
                const self = this.options.ajv.keywords.calibrationRequired
                  .validate as any
                self.errors = []
                const preset =
                  this.presets?.find(({ id }) => id === value) || null
                if (!preset) return false
                if (preset?.groundCalibration?.projectionMatrix?.length) {
                  self.errors = []
                } else {
                  self.errors.push({
                    message: this.$t('calibration.calibrationRequired'),
                    keyword: 'calibrationRequired',
                    params: {
                      keyword: 'calibrationRequired',
                    },
                  })
                }
                return self.errors.length === 0
              },
            },
          },
        },
      }
    },
    mergedParams(): IAssignmentParameters {
      return this.assignments.reduce((acc, assignment) => {
        const { groupParametersId } =
          this.assignmentTypes[assignment.assignmentTypeId] || {}
        if (groupParametersId) {
          acc = merge(acc, this.propertiesMap[groupParametersId])
        }
        return acc
      }, {} as any)
    },
    schema(): any {
      return this.mergedParams.schema || {}
    },
    isStarted(): boolean {
      return this.assignments.some(({ status }) => status === 'Started')
    },
    uiSchema(): UISchema[] | [] {
      if (this.assignments.length > 0) {
        const props = this.mergedParams
        if (props?.viewEditor?.fields) {
          return Object.values(props?.viewEditor?.fields).map((field) => {
            field.errorOptions = { class: ['is-invalid'] }
            field.fieldOptions.on = ['commit']
            field.fieldOptions.props.options.type = 'common'
            if (
              this.isStarted ||
              !this.canI('UpdateAssignmentGroup', this.assignmentGroup)
            ) {
              field.fieldOptions.props.options.disabled = true
            } else {
              field.fieldOptions.props.options.disabled = false
            }
            return { ...field }
          })
        }
      }
      return []
    },
  },
  watch: {
    'assignmentGroup.parameters': {
      handler(val: IAssignmentGroup['parameters']) {
        this.localParams = val
      },
    },
  },
  created() {
    // это костыль для валидатора чтобы он переинициализировался после обновления
    this.$eventBus.$on('updatePresets', this.toogleTrigger)

    const unwatch = this.$watch('presets', () => {
      this.trigger = false
      setTimeout(() => {
        this.trigger = true
      }, 100)
      unwatch()
    })
  },
  mounted() {
    this.$el.addEventListener('commit', () =>
      setTimeout(this.commitUpdate.bind(this)),
    )
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
    ...mapActionsTyped(['updateAssignmentGroup']),
    toogleTrigger() {
      this.trigger = false
      setTimeout(() => {
        this.trigger = true
      }, 100)
    },
    commitUpdate() {
      this.tryCatch(() =>
        this.updateAssignmentGroup({
          ...this.assignmentGroup,
          parameters: this.localParams,
        }),
      )
    },
    onValidated(val: boolean) {
      this.isValid = val
    },
  },
  destroyed() {
    this.$eventBus.$off('updatePresets', this.toogleTrigger)
  },
})

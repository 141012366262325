var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.scaleX !== Infinity)?_c('v-stage',{ref:"stage",style:({
      cursor: _vm.currentDrawTool !== null ? 'crosshair' : 'auto',
    }),attrs:{"config":{
      width: _vm.width,
      height: _vm.height,
      scaleX: _vm.scaleX,
      scaleY: _vm.scaleY,
      pixelRatio: 1,
    }},on:{"click":_vm.handleDrawClick,"mousemove":_vm.updateLastPoint,"contextmenu":_vm.dropDraw}},[_c('v-layer',[_vm._l((_vm.allAreas),function(area){return _c('v-group',{key:area.id},[(
            _vm.currentAssignment === null
              ? !_vm.hiddenAreas.includes(area.id)
              : !_vm.hiddenAreas.includes(area.id) &&
                _vm.matchedZonesSet.includes(area)
          )?_c('v-group',{attrs:{"config":{
            draggable: !_vm.isBlocked(area),
            dragBoundFunc: _vm.dragBoundFuncArea,
          }},on:{"mouseenter":function($event){return _vm.setHovered($event.target, area)},"mouseleave":function($event){return _vm.clearHovered($event.target, area)},"dragstart":function($event){_vm.canI('UpdatePresetArea', area) && _vm.dragAreaStart(area)},"dragend":function($event){return _vm.dragAreaEnd($event, area)},"mousedown":function($event){return _vm.selectArea(area, $event.target)},"mouseup":_vm.blurArea}},[_c('v-line',{attrs:{"config":{
              points: area.absoluteArea?.flat(),
              closed: true,
              stroke: _vm.strokeColorM(area),
              strokeWidth: [_vm.hoveredArea, _vm.editingArea].includes(area)
                ? 6 / _vm.scaleX
                : 3 / _vm.scaleX,
              fill: _vm.fillColorM(area),
            }}}),(
              area.directionType &&
              area.directionType !== 'None' &&
              area.direction
            )?_c('v-group',{attrs:{"config":{
              ..._vm.centroidsMap[area.id],
              name: area.id,
              rotation: _vm.vecToAngle(...area.direction),
            }},on:{"transformend":function($event){return _vm.rotationSave($event, area)}}},[(_vm.centroidsMap[area.id])?_c('v-arrow',{attrs:{"config":{
                points: [0, 0, 0, (0.5 * _vm.arrowLength) / _vm.scaleX],
                rotation: 180,
                fill: '#43FF00',
                stroke: '#43FF00',
                pointerLength: 15 / _vm.scaleX,
                pointerWidth: 15 / _vm.scaleX,
                lineJoin: 'bevel',
                strokeWidth: 2 / _vm.scaleX,
                lineCap: 'round',
              }}}):_vm._e(),(_vm.centroidsMap[area.id])?_c('v-arrow',{attrs:{"config":{
                points: [0, 0, 0, (0.5 * _vm.arrowLength) / _vm.scaleX],
                fill: '#FF3C3C',
                stroke: '#FF3C3C',
                strokeWidth: 2 / _vm.scaleX,
                pointerLength: 15 / _vm.scaleX,
                pointerWidth: 15 / _vm.scaleX,
                lineJoin: 'bevel',
                opacity: area.directionType === 'Bidirectional' ? 1 : 0,
                dashEnabled: true,
                dash: [14, 14],
              }}}):_vm._e()],1):_vm._e(),(_vm.centroidsMap[area.id])?_c('v-text',{attrs:{"config":{
              ..._vm.centroidsMap[area.id],
              text: area.title,
              fill: 'white',
              fontFamily: 'Geometria',
              fontWeight: 500,
              fontSize: [_vm.hoveredArea, _vm.editingArea].includes(area)
                ? 17 / _vm.scaleX
                : 15 / _vm.scaleX,
              align: 'center',
            }}}):_vm._e()],1):_vm._e()],1)}),(
          _vm.editingArea !== null &&
          _vm.canI('UpdatePresetArea', _vm.editingArea) &&
          _vm.isEditable &&
          (_vm.matchedZonesSet.length > 0
            ? _vm.matchedZonesSet.includes(_vm.editingArea)
            : 1)
        )?_c('v-group',[_vm._l((_vm.editingArea.absoluteArea),function(point,index){return _c('v-rect',{key:`${_vm.editingArea.id}${index}`,attrs:{"config":{
            x: point[0],
            y: point[1],
            width: 14 / _vm.scaleX,
            height: 14 / _vm.scaleY,
            offsetX: 7 / _vm.scaleX,
            offsetY: 7 / _vm.scaleY,
            strokeWidth: 2 / _vm.scaleX,
            stroke: 'black',
            fill: 'white',
            draggable: !_vm.isBlocked(_vm.editingArea),
            dragBoundFunc: _vm.dragBoundFunc,
          }},on:{"contextmenu":function($event){return _vm.deletePoint(_vm.editingArea, index)},"dragstart":function($event){_vm.isDragging = true},"dragmove":function($event){return _vm.movePoint(_vm.editingArea, $event, point)},"dragend":function($event){_vm.isDragging = false}}})}),_vm._l((_vm.middlePointsMap[_vm.editingArea.id]),function(point,index){return _c('v-rect',{key:`middle${_vm.editingArea.id}${index}`,attrs:{"config":{
            x: point[0],
            y: point[1],
            width: 14 / _vm.scaleX,
            height: 14 / _vm.scaleY,
            offsetX: 7 / _vm.scaleX,
            offsetY: 7 / _vm.scaleY,
            strokeWidth: 2 / _vm.scaleX,
            stroke: 'black',
            fill: 'white',
            opacity: 0.5,
            draggable: !_vm.isBlocked(_vm.editingArea),
            dragBoundFunc: _vm.dragBoundFunc,
          }},on:{"dragstart":function($event){_vm.isDragging = true},"dragend":function($event){return _vm.addPoint(_vm.editingArea, $event, index)},"click":function($event){return _vm.addPoint(_vm.editingArea, $event, index)}}})})],2):_vm._e(),_c('v-transformer',{ref:"transformer",attrs:{"config":{
          opacity:
            _vm.editingArea &&
            _vm.editingArea.directionType === 'None' &&
            !_vm.isBlocked(_vm.editingArea)
              ? 0
              : 1,
          resizeEnabled: false,
          rotateAnchorOffset: 0,
          borderEnabled: false,
          anchorStroke: 'black',
          anchorStrokeWidth: 2,
          anchorSize: 14,
        }}})],2),(_vm.currentDrawTool !== null)?_c('v-layer',[(_vm.currentDrawingPoints.length >= 2)?_c('v-line',{attrs:{"config":{
          points: [..._vm.currentDrawingPoints, ..._vm.lastPoint],
          closed: false,
          stroke: '#3C72FF',
          strokeWidth: 3 / _vm.scaleX,
        }}}):_vm._e(),(_vm.currentDrawingPoints.length >= 2)?_c('v-line',{attrs:{"config":{
          points: [..._vm.currentDrawingPoints, ..._vm.lastPoint],
          closed: true,
          strokeWidth: 0,
          fill: 'rgba(60, 114, 255, 0.2)',
          zIndex: 10,
        }}}):_vm._e(),(_vm.currentDrawingPoints.length >= 6)?_c('v-rect',{attrs:{"config":{
          x: _vm.currentDrawingPoints[0],
          y: _vm.currentDrawingPoints[1],
          width: 14 / _vm.scaleX,
          height: 14 / _vm.scaleY,
          offsetX: 7 / _vm.scaleX,
          offsetY: 7 / _vm.scaleY,
          stroke: 'rgba(60, 114, 255)',
          strokeWidth: 2 / _vm.scaleX,
          fill: 'white',
          opacity: 1,
        }},on:{"mouseenter":function($event){return _vm.setCursorPoiner($event.target)},"mouseleave":function($event){return _vm.clearHovered($event.target)},"click":function($event){return _vm.closePolygon('Polygon')}}}):_vm._e()],1):_vm._e()],1):_vm._e(),(
      _vm.currentAssignment === null &&
      _vm.assignmentGroup.parameters &&
      _vm.assignmentGroup.parameters.presetId &&
      !_vm.networkError
    )?_c('Movable',{staticStyle:{"top":"calc(50% - 44px)","left":"0","position":"absolute"}},[_c('div',{staticClass:"draw-tools",class:{ 'on-dragging-hide': _vm.isDragging || _vm.currentDrawTool !== null }},_vm._l((_vm.drawTools),function(drawTool){return _c('div',{key:drawTool.name,staticClass:"tool",class:{
          tool__active: drawTool.name === _vm.currentDrawTool,
          tool__disabled: drawTool.disabled,
        },attrs:{"title":drawTool.label},on:{"click":function($event){!drawTool.disabled && drawTool.action(drawTool)}}},[_c('i',{staticClass:"mdi mdi-20px",class:drawTool.icon})])}),0)]):_vm._e(),(_vm.assignmentGroup.parameters && _vm.assignmentGroup.parameters.presetId)?_c('Movable',{staticStyle:{"top":"30px","left":"0","position":"absolute"}},[_c('div',{staticClass:"direction-tools",class:{ 'on-dragging-hide': _vm.isDragging || _vm.currentDrawTool !== null }},_vm._l((_vm.additionalTools),function(additionalTool){return _c('div',{key:additionalTool.name,staticClass:"tool",class:{
          tool__disabled: additionalTool.disabled,
        },attrs:{"title":additionalTool.label},on:{"click":function($event){!additionalTool.disabled && additionalTool.action(additionalTool)}}},[_c('i',{staticClass:"mdi mdi-20px",class:additionalTool.icon})])}),0)]):_vm._e(),_c('div',{staticStyle:{"position":"absolute"}},[_c('YesNoDialog',{attrs:{"show":_vm.displayDeleteDialog,"message":_vm.$t('message.deleteArea'),"header":_vm.$t('message.deleteTitle')},on:{"update:show":function($event){_vm.displayDeleteDialog=$event},"onYes":_vm.deleteCurrentArea}}),_c('YesNoDialog',{attrs:{"show":_vm.displayEditBlocked,"message":_vm.$t('message.editBlocked'),"header":_vm.$t('message.editTitle')},on:{"update:show":function($event){_vm.displayEditBlocked=$event},"onYes":() => {
          _vm.ignoredBlocking.push(_vm.ignoringCandidate)
          _vm.initMemoizes()
        }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
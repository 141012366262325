// @ts-ignore
import intersector from 'line-intersection'

type Vector = { x: number; y: number }
type Segment = { start: XYpoint; end: XYpoint }
type Line = { start: XYpoint; end: XYpoint }
type Ray = { start: XYpoint; end: XYpoint }

const scalarMultiply = (vector1: Vector, vector2: Vector) => {
  return vector1.x * vector2.x + vector1.y * vector2.y
}

const isPointOnRay = (ray: Ray, point: XYpoint) => {
  const firstVector = {
    x: ray.end.x - ray.start.x,
    y: ray.end.y - ray.start.y,
  }
  const secondVector = { x: point.x - ray.start.x, y: point.y - ray.start.y }
  const scalarMult = scalarMultiply(firstVector, secondVector)
  return scalarMult > 0
}

export const getSegmentsIntersection = (segment1: Segment, segment2: Segment) => {
  return intersector.findSegmentIntersection([
    segment1.start,
    segment1.end,
    segment2.start,
    segment2.end,
  ])
}

export const getLinesIntersection = (line1: Line, line2: Line): XYpoint => {
  return intersector.findIntersection([line1.start, line1.end, line2.start, line2.end])
}

export const getRaySegmentIntersection = (ray: Ray, segment: Segment): IPoint | undefined => {
  const result = intersector.findIntersection([ray.start, ray.end, segment.start, segment.end])
  if (
    !result ||
    !intersector.isPointBetween({ x: result.x, y: result.y }, segment.start, segment.end)
  ) {
    return
  }
  if (isPointOnRay(ray, result)) {
    return result
  }
}

export const getLineSegmentIntersection = (line: Line, segment: Segment) => {
  const result = intersector.findIntersection([line.start, line.end, segment.start, segment.end])
  if (
    intersector.isPointBetween(
      { x: Math.round(result.x), y: Math.round(result.y) },
      segment.start,
      segment.end,
    )
  ) {
    return result
  }
}

export const getRayIntersectionsWithScreenBounds = (
  startPoint: XYpoint,
  rayPoint: Ray['end'],
  width: number,
  height: number,
): IPoint[] => {
  const ray = {
    start: startPoint,
    end: rayPoint,
  }

  const intersections = []
  let result = getRaySegmentIntersection(ray, {
    start: { x: 0, y: 0 },
    end: { x: width, y: 0 },
  })
  if (result) {
    intersections.push(result)
  }

  result = getRaySegmentIntersection(ray, {
    start: { x: width, y: 0 },
    end: { x: width, y: height },
  })
  if (result) {
    intersections.push(result)
  }

  result = getRaySegmentIntersection(ray, {
    start: { x: width, y: height },
    end: { x: 0, y: height },
  })
  if (result) {
    intersections.push(result)
  }

  result = getRaySegmentIntersection(ray, {
    start: { x: 0, y: height },
    end: { x: 0, y: 0 },
  })
  if (result) {
    intersections.push(result)
  }
  return intersections
}

export const getLineIntersectionsWithScreenBounds = (
  point1: XYpoint,
  point2: XYpoint,
  width: number,
  height: number,
) => {
  const line = {
    start: point1,
    end: point2,
  }

  const intersections = []
  let result = getLineSegmentIntersection(line, {
    start: { x: 0, y: 0 },
    end: { x: width, y: 0 },
  })
  if (result) {
    intersections.push(result)
  }

  result = getLineSegmentIntersection(line, {
    start: { x: width, y: 0 },
    end: { x: width, y: height },
  })
  if (result) {
    intersections.push(result)
  }

  result = getLineSegmentIntersection(line, {
    start: { x: width, y: height },
    end: { x: 0, y: height },
  })
  if (result) {
    intersections.push(result)
  }

  result = getLineSegmentIntersection(line, {
    start: { x: 0, y: height },
    end: { x: 0, y: 0 },
  })
  if (result) {
    intersections.push(result)
  }
  return intersections
}

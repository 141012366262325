
import { mapActionsTyped, mapGettersTyped } from '@/store'
import BatchQueue from '../common/BatchQueue'

export default BatchQueue.extend({
  name: 'StartButtons',
  props: {
    assignment: Object as () => IAssignment | IAssignmentGroup,
  },
  data() {
    return {
      assignmentStatus: (this.assignment.status as string) || '',
    }
  },
  watch: {
    'assignment.status': {
      handler(newValue) {
        this.assignmentStatus = newValue
      },
    },
  },
  computed: {
    ...mapGettersTyped(['assignmnentIdsWithErrors']),
  },
  methods: {
    ...mapActionsTyped(['commitCommand']),
    startAssignment() {
      this.assignment &&
        this.tryCatch(
          () => this.commitCommand([this.assignment, 'startCommand']),
          `${this.$t('errorMessages.assignmentStartingError')}`,
        )
    },
    stopAssignment() {
      this.assignment &&
        this.tryCatch(
          () => this.commitCommand([this.assignment, 'stopCommand']),
          `${this.$t('errorMessages.assignmentStopError')}`,
        )
    },
  },
})

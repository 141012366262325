import { createCamerasConnection } from '@netvision/lib-api-gateway'

export const createEntity = async <T extends IEntity>(
  entity: T,
): Promise<T> => {
  return createCamerasConnection()
    .v2.createEntity(entity, {
      keyValues: true,
    })
    .catch((error: Error) => {
      console.error(error)
      throw error
    })
}

export const deleteEntity = async <T extends IEntity>({ id, type }: T) => {
  return createCamerasConnection().v2.deleteEntity({
    type,
    id,
  })
}

const videoCahe = { cache: new Map() as Map<string, string> }

export const fetchPosterVideo = async ({ headers, posterUrl, isForced }: IPosterVideoFetchConfig) => {
  if (!posterUrl) throw Error('Preview cannot be fetched')
  try {
    const cachedUrl = videoCahe.cache.get(posterUrl)
    if (cachedUrl && !isForced) {
      return cachedUrl
    }
    const res = await fetch(posterUrl, { headers })

    if (
      res.status === 200
      // TODO enable when backed will send proper content type
      // && res.headers.get('Content-Type') === 'video/mp4'
    ) {
      const internalUrl = window.URL.createObjectURL(
        new Blob([await res.blob()]),
      )
      videoCahe.cache.set(posterUrl, internalUrl)
      return internalUrl
    } else {
      throw Error('Preview cannot be fetched')
    }
  } catch (error) {
    console.error(error)
  }
}
export const clearPosterVideoCahce = () => {
  videoCahe.cache = new Map()
}
export const updateEntity = async <T extends IEntity>(
  entity: T,
  changes: Partial<T>,
) => {
  const { id, type } = entity
  const updateAttrs = { id, type } as Partial<T>
  const appendAttrs = { id, type } as Partial<T>
  let isSuccess = true

  ;(Object.keys(changes) as (keyof typeof changes)[]).forEach((key) => {
    if (key in entity) {
      updateAttrs[key] = changes[key]
    } else {
      appendAttrs[key] = changes[key]
    }
  })

  Object.keys(updateAttrs).length > 2 &&
    (await createCamerasConnection()
      .v2.updateEntityAttributes(updateAttrs, {
        keyValues: true,
      })
      .catch((error: Error) => {
        isSuccess = false
        console.error(error)
        throw Error
      }))

  Object.keys(appendAttrs).length > 2 &&
    (await createCamerasConnection()
      .v2.appendEntityAttributes(appendAttrs, {
        keyValues: true,
      })
      .catch((error: Error) => {
        isSuccess = false
        console.error(error)
        throw Error
      }))
  return isSuccess
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-stage',{ref:"stage",attrs:{"config":{ width: _vm.width, height: _vm.height }}},[_c('v-layer',_vm._l((_vm.gridLines),function(gridLine,index){return _c('v-line',{key:`gridLine${gridLine[0]}_${gridLine[1]}${index}`,attrs:{"config":{
        points: gridLine,
        stroke: '#3C72FF',
        opacity: 0.7,
      }}})}),1),_c('v-layer',{ref:"calibrationLayer"},[_c('v-line',{attrs:{"config":{
        points: _vm.naturalPoints.flat(),
        closed: true,
        stroke: '#3C72FF',
        strokeWidth: 6,
        shadowColor: '#3C72FF',
        fill: 'rgba(60, 114, 255, 0.2)',
        shadowBlur: 10,
      }}}),_vm._l((_vm.sidesMap),function(side){return _c('v-text',{key:`${side}`,attrs:{"config":{
        text:
          `${_vm.groundCalibration[side[2]]}` +
          ` м${side[3] ? `, ${side[3]}` : ''}`,
        x:
          (_vm.width *
            (_vm.groundCalibration.rectanglePoints[side[0]].x +
              _vm.groundCalibration.rectanglePoints[side[1]].x)) /
          2,
        y:
          (_vm.height *
            (_vm.groundCalibration.rectanglePoints[side[0]].y +
              _vm.groundCalibration.rectanglePoints[side[1]].y)) /
          2,
        fontSize: _vm.height / 50,
        fill: 'white',
        fontFamily: 'Geometria',
        fontWeight: 500,
        align: 'center',
        verticalAlign: 'middle',
      }}})}),_vm._l((_vm.naturalPoints),function(point,index){return _c('v-rect',{key:`${index}`,attrs:{"config":{
        x: point[0],
        y: point[1],
        width: _vm.height / 60,
        height: _vm.height / 60,
        offsetX: _vm.height / 120,
        offsetY: _vm.height / 120,
        stroke: 'black',
        fill: 'white',
        draggable: true,
        dragBoundFunc: _vm.dragBoundFunc,
      }},on:{"dragmove":function($event){return _vm.movePoint($event, index)}}})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import Te from '../common/Te'
import { mapStateTyped, mapGettersTyped } from '@/store'
import InlineMessage from 'primevue/inlinemessage'
import { capitalize } from '@/utils'

type IMessage = {
  message: string
  title?: string
  severity: 'warn' | 'error' | 'info' | 'success'
}

export default Te.extend({
  name: 'Messages',
  components: {
    InlineMessage,
  },
  props: {},
  data() {
    return {
      currentIndex: 0,
    }
  },
  computed: {
    ...mapStateTyped([
      'errorsState',
      'zonesRequirements',
      'assignmentTypes',
      'currentDrawTool',
      'currentDrawingPoints',
    ]),
    ...mapGettersTyped(['currentAssignment', 'rejectStatuses']),
    messages(): IMessage[] {
      return [
        ...this.drawMessage,
        ...this.rejectReasonsMessages,
        ...this.zoneRequirementsMessages,
        ...this.errorMessages,
      ]
    },
    assignmentType(): IAssignmentType | null {
      if (this.assignmentTypes && this.currentAssignment) {
        return (
          this.assignmentTypes[this.currentAssignment.assignmentTypeId] || null
        )
      }
      return null
    },
    drawMessage(): IMessage[] {
      const drawedPoints = this.currentDrawingPoints.length / 2
      let message
      switch (true) {
        case this.currentDrawTool && drawedPoints === 0:
          message = `${this.$t('message.drawStartHint')}`
          break
        case this.currentDrawTool === 'Polygon':
          if (drawedPoints > 0 && drawedPoints < 3) {
            message = `${this.$t('message.drawMiddlePoligonPoints')}`
          } else {
            message = `${this.$t('message.drawEndPolygonHint')}`
          }
          break
        case this.currentDrawTool === 'Line':
          message = `${this.$t('message.drawEndLineHint')}`
      }
      return message ? [this.makeInfoMessage(message)] : []
    },
    zoneRequirementsMessages(): IMessage[] {
      if (!this.currentAssignment) return []
      const zonesRequirements =
        this.zonesRequirements[this.currentAssignment.id]
      return zonesRequirements
        ? Object.entries(zonesRequirements).reduce((acc, [key, value]) => {
            value &&
              acc.push({
                severity: 'info',
                message: `${this.$t(
                  `assignments.${this.assignmentType?.name}.attrs.${key}`,
                )}: ${value}`,
              })
            return acc
          }, [] as IMessage[])
        : []
    },
    rejectReasonsMessages(): IMessage[] {
      if (!this.currentAssignment) return []
      const rejectReason = this.rejectStatuses[this.currentAssignment.id]
      return rejectReason
        ? [
            {
              severity: 'warn',
              title: this.currentAssignment.title,
              message: `${this.$t(`assignmentRejectReason.${rejectReason}`)}`,
            },
          ]
        : []
    },
    errorMessages(): IMessage[] {
      if (!this.currentAssignment) return []
      const type = this.errorsState[this.currentAssignment.id]?.type
      // here replacing field on their localization
      return (
        this.errorsState[this.currentAssignment.id]?.state?.vfjsErrors?.map(
          (jError: any) => {
            const error = {
              ...jError,
              severity: 'error',
              title: this.$t('error'),
            }
            const { message, params, dataPath } = error
            if (error.keyword === 'required') {
              return {
                ...error,
                message: message.replace(
                  params.missingProperty,
                  `"${this.te(
                    `assignments.${type}.attrs.${params.missingProperty}`,
                    params.missingProperty,
                  )}"`,
                ),
              }
            }
            if (error.keyword === 'minItems') {
              return {
                ...error,
                message: message.replace(
                  dataPath,
                  `"${this.te(
                    `assignments.${type}.attrs${dataPath}`,
                    dataPath,
                  )}"`,
                ),
              }
            }
            return error
          },
        ) || []
      )
    },
  },
  watch: {
    messages(newVal) {
      if (newVal.length > 0 && this.currentIndex > newVal.length - 1) {
        this.currentIndex = newVal.length - 1
      }
    },
  },
  methods: {
    capitalize,
    makeInfoMessage(message: string) {
      return {
        severity: 'info',
        message,
      } as IMessage
    },
  },
})

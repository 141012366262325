
import Vue from 'vue'

export default Vue.extend({
  name: 'FieldLabel',
  props: {
    label: { type: String, default: '' },
    type: { type: String, default: '' },
  },
})


import Dropdown from 'primevue/dropdown'
import Te from '../common/Te'
import { mapStateTyped } from '@/store'

const commit = new CustomEvent('commit', {
  bubbles: true,
})

type Options = {
  label: string
  type: string
  defaultStreamType: string
} & UISchema['fieldOptions']['props']['options']

export default Te.extend({
  name: 'StreamTypeField',
  components: {
    Dropdown,
  },
  props: {
    value: { type: String, default: undefined },
    options: Object as () => Options,
  },
  data() {
    return {
      streamsMap: {} as Record<string, string>,
      streamOptions: [] as {
        title: string
        value: string
        streamType: string
      }[],
    }
  },
  computed: {
    ...mapStateTyped(['api', 'assignmentGroup', 'streams']),
    localValue: {
      set(val: string) {
        this.$emit('commit', val)
        this.$el?.dispatchEvent(commit)
      },
      get(): string {
        return this.value
      },
    },
  },
  watch: {
    streams: {
      handler(newStreams: Stream[]) {
        newStreams.forEach(({ title, id, streamType }) => {
          this.streamsMap[id] = title
          this.streamOptions = [
            ...this.streamOptions,
            { title, value: id, streamType },
          ]
        })

        if (this.localValue) return

        let val = this.localValue
        if (newStreams.length === 1) {
          val = newStreams[0]?.id
        }

        if (this.options.defaultStreamType) {
          const defaultStream = newStreams.find(
            ({ streamType }) => streamType === this.options.defaultStreamType,
          )?.id
          defaultStream && (val = defaultStream)
        }
        this.localValue = val
      },
      immediate: true,
    },
  },
  methods: {},
})

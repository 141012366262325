
import { mapGettersTyped, mapStateTyped } from '../store'
import BatchQueue from './common/BatchQueue'
import AssignmentsBlock from './blocks/AssignmentsBlock.vue'
import PropertiesBlock from './blocks/PropertiesBlock.vue'
import CommonPropertiesBlock from './blocks/CommonPropertiesBlock.vue'
import AreasBlock from './blocks/AreasBlock.vue'
import ArchiveEditor from './editors/ArchiveEditor.vue'
import AreasEditor from './editors/AreasEditor.vue'
import CalibrationEditor from './editors/CalibrationEditor.vue'
import PresetEditor from './editors/PresetEditor.vue'

export default BatchQueue.extend({
  name: 'Editor',
  components: {
    AssignmentsBlock,
    PropertiesBlock,
    ArchiveEditor,
    AreasEditor,
    CalibrationEditor,
    CommonPropertiesBlock,
    AreasBlock,
    PresetEditor,
  },
  data() {
    return {
      isUpdatingProcess: false,
      createQueue: [] as (IAssignmentGroup | IAssignment)[],
      updateQueue: [] as (IAssignmentGroup | IAssignment)[],
      localAssignmentGroupParams: {} as IAssignmentGroup['parameters'],
    }
  },
  computed: {
    ...mapStateTyped([
      'editorMode',
      'propertiesMap',
      'assignmentTypes',
      'assignmentGroup',
      'networkError',
    ]),
    ...mapGettersTyped([
      'currentAssignment',
      'assignmnentIdsWithErrors',
      'assignments',
    ]),
    assignmentType(): IAssignmentType | null {
      if (this.assignmentTypes && this.currentAssignment) {
        return (
          this.assignmentTypes[this.currentAssignment.assignmentTypeId] || null
        )
      }
      return null
    },
    areasUiSchemas(): [IAssignment, UISchema[]][] | [] {
      return this.assignments.reduce((acc, assignment) => {
        const props =
          this.propertiesMap[
            this.assignmentTypes[assignment.assignmentTypeId].parametersId
          ]

        if (props?.viewEditor?.fields) {
          const areasProps = Object.values(props?.viewEditor?.fields)
            .filter(({ component }) => {
              return component === 'area'
            })
            .map((uiSchema: UISchema) => {
              const field = { ...uiSchema }
              field.fieldOptions.props.options.type = this.assignmentType?.name
              return field
            })

          areasProps?.length &&
            areasProps?.length > 0 &&
            acc.push([assignment, areasProps])
        }
        return acc
      }, [] as any)
    },
  },
})

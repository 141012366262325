
import Vue from 'vue'

export default Vue.extend({
  name: 'ArchiveEditor',
  components: {},
  data() {
    return {}
  },
  computed: {},
})

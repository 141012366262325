
import Vue from 'vue'
import { ProjectionCalculator2d } from 'projection-3d-2d'
import {
  getRayIntersectionsWithScreenBounds,
  getLinesIntersection,
} from '../common/boundsIntersector'

const STEP_IN_METERS = 10
const BOUNDS_IN_METERS = 200
let projectionCalculator = null as ProjectionCalculator2d | null

export default Vue.extend({
  props: {
    width: { type: Number, default: 1024, required: true },
    height: { type: Number, default: 800, required: true },
    groundCalibration: {
      type: Object as () => GroundCalibration,
      default: (): GroundCalibration => ({
        rectangleHeight: 10,
        rectangleWidth: 10,
        projectionMatrix: [],
        rectanglePoints: [
          { x: 0.33, y: 0.25 },
          { x: 0.66, y: 0.25 },
          { x: 0.8, y: 0.8 },
          { x: 0.2, y: 0.81 },
        ],
      }),
    },
    showGrid: Boolean,
  },
  data() {
    return {
      calibrationLayer: {} as any,
      stage: {} as any,
      layer: undefined,
      sidesMap: [
        [0, 1, 'rectangleWidth'],
        [1, 2, 'rectangleHeight'],
        [2, 3, 'rectangleWidth', this.$t('calibration.Width')],
        [3, 0, 'rectangleHeight', this.$t('calibration.Height')],
      ] as [number, number, keyof GroundCalibration, string][],
    }
  },
  computed: {
    naturalPoints(): IPoint[] {
      return this.groundCalibration.rectanglePoints.map((point) => {
        return [point.x * this.width, point.y * this.height]
      })
    },
    gridLines(): number[][] {
      if (!this.showGrid) {
        return []
      }
      const rectPoints = this.groundCalibration.rectanglePoints
      const vanishingPoint1 = getLinesIntersection(
        { start: rectPoints[1], end: rectPoints[2] },
        { start: rectPoints[0], end: rectPoints[3] },
      )
      const vanishingPoint2 = getLinesIntersection(
        { start: rectPoints[0], end: rectPoints[1] },
        { start: rectPoints[2], end: rectPoints[3] },
      )
      const vanishingPoints = [
        [vanishingPoint1.x, vanishingPoint1.y],
        [vanishingPoint2.x, vanishingPoint2.y],
      ] as IPoint[]
      const lines = []
      const k =
        (vanishingPoints[0][1] - vanishingPoints[1][1]) /
        (vanishingPoints[0][0] - vanishingPoints[1][0])
      const b = vanishingPoints[0][1] - k * vanishingPoints[0][0]
      for (
        let i = -BOUNDS_IN_METERS;
        i <= BOUNDS_IN_METERS;
        i += STEP_IN_METERS
      ) {
        const horizontalLine = this.calcGridLine(
          vanishingPoints[0],
          { x: i, y: 0 },
          k,
          b,
        )
        horizontalLine && lines.push(horizontalLine)
        const verticaltalLine = this.calcGridLine(
          vanishingPoints[1],
          { x: 0, y: i },
          k,
          b,
        )
        verticaltalLine && lines.push(verticaltalLine)
      }
      return lines
    },
  },
  watch: {
    'groundCalibration.rectangleHeight': {
      handler() {
        this.calcMatrix()
      },
    },
    'groundCalibration.rectangleWidth': {
      handler() {
        this.calcMatrix()
      },
    },
  },
  beforeMount() {
    this.createProjectionCalculator()
    this.calcMatrix()
  },
  methods: {
    movePoint(event: any, index: number) {
      const position = {} as XYpoint
      position.x = event.target.attrs.x / this.width
      position.y = event.target.attrs.y / this.height
      const groundCalibration = { ...this.groundCalibration }
      groundCalibration.rectanglePoints[index] = position
      this.$emit('update:groundCalibration', groundCalibration)
      this.calcMatrix()
    },
    dragBoundFunc({ x, y }: { x: number; y: number }) {
      return {
        x: x > 0 ? (x >= this.width ? this.width : x) : 0,
        y: y > 0 ? (y >= this.height ? this.height : y) : 0,
      }
    },
    calcGridLine(
      vanishingPoint: IPoint,
      point3d: XYpoint,
      k: number,
      b: number,
    ): number[] | undefined {
      if (!projectionCalculator) return
      const point2d = projectionCalculator.getProjectedPoint([
        point3d.x,
        point3d.y,
      ])
      if (!point2d) return
      if (point2d[1] < point2d[0] * k + b) {
        return
      }
      const intersections = getRayIntersectionsWithScreenBounds(
        { x: vanishingPoint[0], y: vanishingPoint[1] },
        { x: point2d[0], y: point2d[1] },
        1,
        1,
      )
      let line = [] as number[][]
      if (intersections.length === 1) {
        line = [
          [vanishingPoint[0], vanishingPoint[1]],
          Object.values(intersections[0]),
        ]
      }
      if (intersections.length === 2) {
        line = [
          Object.values(intersections[0]),
          Object.values(intersections[1]),
        ]
      }
      return line.map(([x, y]) => [x * this.width, y * this.height]).flat()
    },
    createProjectionCalculator() {
      const width = this.groundCalibration.rectangleWidth
      const height = this.groundCalibration.rectangleHeight
      const rectPoints = this.groundCalibration.rectanglePoints
      const points2d = rectPoints.map((point) => [point.x, point.y]) as [
        [number, number],
        [number, number],
        [number, number],
        [number, number],
      ]
      projectionCalculator = new ProjectionCalculator2d(
        [
          [0, 0],
          [width, 0],
          [width, height],
          [0, height],
        ],
        points2d,
      )
    },
    calcMatrix() {
      this.createProjectionCalculator()
      const matrixToSave = []
      for (let i = 0; i <= 2; i++) {
        for (let j = 0; j <= 2; j++) {
          // @ts-ignore
          matrixToSave.push(projectionCalculator.resultMatrixInversed[i][j])
        }
      }
      const groundCalibration = { ...this.groundCalibration }
      groundCalibration.projectionMatrix = matrixToSave
      this.$emit('update:groundCalibration', groundCalibration)
    },
  },
})


import Vue from 'vue'
export default Vue.extend({
  name: 'CameraCard',
  inject: ['spaParent'],
  props: {
    cameraId: { type: String, default: () => '' },
  },
  data() {
    return {
      unmount: () => {},
      cameraKeyNames: ['player', 'live']
    }
  },
  mounted() {
    this.initPlayer()
  },
  destroyed() {
    this.unmount()
  },
  methods: {
    initPlayer() {
      let ref = this.$refs[`player-${this.cameraId}`] as
        | HTMLElement
        | HTMLElement[]
      if (Array.isArray(ref)) ref = ref[0] as HTMLElement
      // @ts-ignore
      const spa = this.spaParent as any
      const player = spa?.areas?.find((e: any) => this.cameraKeyNames.includes(e.name))
      if (player) {
        this.unmount = spa.mountChildren(
          ref,
          player.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...(e.props || {}),
                initiallyShowPtz: true,
                updatePresetImageOn: true,
                cameraId: this.cameraId,
                ratio: 'fit',
                ptzRequiresFullscreen: false,
                autoplay: true,
              },
            }
          }),
        )
      }
    },
  },
})

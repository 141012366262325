
import { PropType } from 'vue'
import FieldsBlock from '../common/FieldsBlock.vue'
// @ts-ignore
import VueFormJsonSchema from 'vue-form-json-schema'
import components from '../common/fieldsMap'
import { localize } from '@/translations/schemaLocal'
import { mapStateTyped, mapMutationsTyped, mapActionsTyped } from '@/store'
import BatchQueue from '../common/BatchQueue'

export default BatchQueue.extend({
  name: 'PropertiesBlock',
  components: {
    FieldsBlock,
    VueFormJsonSchema,
  },
  props: {
    assignment: { type: Object as PropType<IAssignment>, default: undefined },
  },
  data() {
    return {
      components,
      state: {} as any,
      isValid: true,
      lastValidation: true,
      options: {
        ajv: {
          locale: localize,
        },
        strictSchema: true,
        showValidationErrors: true,
      },
      aggregator: {} as IAssignment['parameters'],
      localAssignmentParameters: this.assignment.parameters,
    }
  },
  computed: {
    ...mapStateTyped([
      'assignmentTypes',
      'propertiesMap',
      'assignmentGroup',
      'errorsState',
      'widgetProps',
    ]),
    assignmentType(): IAssignmentType | null {
      if (this.assignmentTypes && this.assignment) {
        return this.assignmentTypes[this.assignment.assignmentTypeId] || null
      }
      return null
    },
    canIUpdateAssignment(): boolean {
      return this.canI('UpdateAssignment')
    },
    schema(): any | null {
      if (this.assignmentType) {
        const props = this.propertiesMap[this.assignmentType.parametersId]
        return props?.schema || {}
      }
      return null
    },
    uiSchema(): UISchema[] | undefined {
      if (this.assignmentType) {
        const props = this.propertiesMap[this.assignmentType.parametersId]
        if (props?.viewEditor?.fields) {
          return Object.values(props?.viewEditor?.fields).map((field) => {
            field.internalModel = false
            field.errorOptions = { class: ['is-invalid'] }
            field.fieldOptions.on = ['commit']
            field.fieldOptions.props.options.type = this.assignmentType?.name
            if (!this.canIUpdateAssignment) {
              field.fieldOptions.props.options.disabled = true
            } else {
              field.fieldOptions.props.options.disabled = false
            }
            return { ...field }
          })
        }
      }
      return []
    },
  },
  watch: {
    'assignment.parameters': {
      handler(val) {
        this.localAssignmentParameters = val
      },
    },
  },
  mounted() {
    this.$el.addEventListener('commit', () =>
      setTimeout(this.commitAssignment.bind(this)),
    )
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
    ...mapActionsTyped(['updateAssignment']),
    commitAssignment() {
      this.tryCatch(() =>
        this.updateAssignment({
          ...this.assignment,
          parameters: this.localAssignmentParameters,
        }),
      )
    },
    onChangeValidatorState(state: any) {
      this.setValue([
        'errorsState',
        {
          ...this.errorsState,
          [this.assignment.id]: { state, type: this.assignmentType?.name },
        },
      ])
    },
    onValidated(val: boolean) {
      this.isValid = val
    },
  },
})
